import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import Send from '@material-ui/icons/Send';

import Snackbar from "components/Snackbars/Snackbar";
import 'date-fns'
import { es } from "date-fns/locale";
import  format from 'date-fns/format'
import  DateFnsUtils  from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker  } from '@material-ui/pickers';
import {Redirect} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import UsuariosDataService from "services/usuarios.service";
class VerificationBirthdayForm extends React.Component {
    constructor(props) {
      super(props);
      var actual = new Date()
      actual.setFullYear(actual.getFullYear()-18)
      this.state = { "date": actual,  "form": {"date": format(actual, "dd/MM/yyyy") } , "status": {"show":false,"redirect":false, "msg": "", "status":"success", "next":"/"}, "loading": false};
      
    }
    handleClearSnack = (event) =>{
      let statusCopy = Object.assign({}, this.state);
      statusCopy.status.show= false;
      statusCopy.status.msg = "";
      statusCopy.status.status = "success";
      this.setState(statusCopy);
    }
  
    handleDateChange = (date) => {
      try {
        var formatDate = format(date, "dd/MM/yyyy")
        this.setState({"form": {"date": formatDate}});
      } catch (error) {
      }
      this.setState({"date": date});
    };
    
  
  
    handleSubmit = (event) => {
      this.handleClearSnack(event);
      var data = JSON.stringify(this.state.form);
      this.setState({"loading": true})
      UsuariosDataService.verifyBirthday(data)
        .then(response => {
          if (response.data.code === 1){
            let statusCopy = Object.assign({}, this.state);
            statusCopy.status.show= true;
            statusCopy.status.msg = response.data.message;
            statusCopy.status.status = "success";
            statusCopy.status.redirect = true;
            statusCopy.status.next = response.data.next.replace("/api/", "/");
            this.setState(statusCopy)
          }else{
            let statusCopy = Object.assign({}, this.state);
            statusCopy.status.show= true;
            statusCopy.status.msg = response.data.message;
            statusCopy.status.status = "error";
            this.setState(statusCopy)
          }
          this.setState({"loading": false})
        })
        .catch(e => {
          this.setState({"loading": false})
          if (e.response.status === 401){
            let statusCopy = Object.assign({}, this.state);
            statusCopy.status.show= true;
            statusCopy.status.msg = e.response.data.message;
            statusCopy.status.status = "error";
            statusCopy.status.redirect = true;
            statusCopy.status.next = "/";
            this.setState(statusCopy)
          }
          console.log(e);
        })
      event.preventDefault();
  }
  // The first commit of Material-UI
 

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  
    render() {
      if (this.state.status.redirect){
        return <Redirect push to={this.state.status.next} />
      }
      
      return (
        <form  autoComplete="off" onSubmit={this.handleSubmit}>
            <Card elevation={3}>
                <CardContent>
                    {/* <TextField onChange={this.handleChange} name="date" fullWidth id="standard-basic" label="Número de Registro Universitario" variant="outlined"
                    helperText="De cualquier carrera en la que se encuentre matriculados"
                    /> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
                      <KeyboardDatePicker required
                      disableFuture
                      autoOk
                      openTo="year"
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de nacimiento"
                      views={["year", "month", "date"]}
                      minDateMessage="Fecha de nacimiento no válida"
                      minDate={new Date().setFullYear(new Date().getFullYear()-100)}
                      maxDateMessage="Fecha de nacimiento no válida"
                      maxDate={new Date().setFullYear(new Date().getFullYear()-15)}
                      value={this.state.date}
                      onChange={this.handleDateChange}
                      invalidDateMessage="Ingrese una fecha válida"
                      KeyboardButtonProps={{
                          'aria-label': 'change date',
                      }}
                      />
                    </MuiPickersUtilsProvider>
                </CardContent>
                <CardActions>
                <Button fullWidth
                    disabled={this.state.loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<Send/>}
                >
                    Continuar
                </Button>
                {this.state.loading && <CircularProgress size={24} />}
                </CardActions>
                {this.state.status.show ? <Snackbar msg={this.state.status.msg} status={this.state.status.status} clear={this.handleClearSnack}/> : null} 
            </Card>
        </form>
      );
    }
  }

export default VerificationBirthdayForm;