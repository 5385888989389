import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Send from '@material-ui/icons/Send';

import CircularProgress from '@material-ui/core/CircularProgress';
import RadioButtonEstamento from "components/RadioButtons/RadioButtonEstamento";
import Snackbar from "components/Snackbars/Snackbar";

import {Redirect} from 'react-router-dom';
import UsuariosDataService from "../../services/usuarios.service";
import Captcha from "../utils/Captcha";
class LoginForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = { "form": {"ci" : "", "type": "estudiante","g-recaptcha-response": ""} , "status": {"show":false,"redirect":false, "msg": "", "status":"success", "next":"/"}, "loading": false, "admin": false};
      UsuariosDataService.getAuthToken(props.logout)
      this.recaptchaRef = React.createRef();
    }
    handleClearSnack = (event) =>{
      let statusCopy = Object.assign({}, this.state);
      statusCopy.status.show= false;
      statusCopy.status.msg = "";
      statusCopy.status.status = "success";
      this.setState(statusCopy);
    }
  
    handleChange = (event) => {
      let statusCopy = Object.assign({}, this.state);
      statusCopy.form[event.target.name]= event.target.value
      this.setState(statusCopy);
      if (event.target.name === "type"){
        if (event.target.value === "administrativo" ){
          this.setState({"admin":true})
        }else{
          this.setState({"admin":false})
        }
      }
    }
  
    handleSubmit = (event) => {
      this.handleClearSnack(event);
      var data = JSON.stringify(this.state.form);
      this.setState({"loading": true})
      UsuariosDataService.login(data)
        .then(response => {
          if (response.data.code === 1){
            localStorage.setItem('currentUserToken', response.data.token);
            localStorage.setItem('currentUserExpires', response.data.expires);
            let statusCopy = Object.assign({}, this.state);
            statusCopy.status.show= true;
            statusCopy.status.msg = response.data.message;
            statusCopy.status.status = "success";
            statusCopy.status.redirect = true;
            statusCopy.status.next = response.data.next.replace("/api/", "/");
            this.setState(statusCopy)
            this.props.login();
          }else{
            let statusCopy = Object.assign({}, this.state);
            statusCopy.status.show= true;
            statusCopy.status.msg = response.data.message;
            statusCopy.status.status = "error";
            this.setState(statusCopy)
            if (this.recaptchaRef !== null){this.recaptchaRef.current.resetCaptcha()}
          }
          this.setState({"loading": false})
        })
        .catch(e => {
          this.setState({"loading": false})
          console.log(e);
          if (this.recaptchaRef !== null){this.recaptchaRef.current.resetCaptcha()}
        })
      event.preventDefault();
  }

  onChangeRecaptcha = (value) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form["g-recaptcha-response"] = value
    this.setState(statusCopy);
  }
  
    render() {
      return (
        <form  autoComplete="off" onSubmit={this.handleSubmit}>
            <Card elevation={3}>
                <CardContent>
                    <RadioButtonEstamento fullWidth  change={this.handleChange}/>
                </CardContent>
            </Card>
            <br/>
            <Card elevation={3}>
                <CardContent>
                    <TextField onChange={this.handleChange} name="ci" fullWidth id="standard-basic" label={this.state.admin ? "Carnet de Identidad o Codigo de Activación" : "Carnet de Identidad"} variant="outlined"
                    helperText="Número de identidad sin extensión" required
                    />
                    <br></br>
                    <br></br>
                    <Captcha onChangeRecaptcha={this.onChangeRecaptcha} ref={this.recaptchaRef} darkState={this.props.darkState} ></Captcha>
                </CardContent>
                <CardActions>
                <Button fullWidth
                    disabled={this.state.loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<Send/>}
                >
                    Continuar 
                </Button>
                {this.state.loading && <CircularProgress size={24} />}
                </CardActions>
                {this.state.status.show ? <Snackbar msg={this.state.status.msg} status={this.state.status.status} clear={this.handleClearSnack}/> : null} 
                {this.state.status.redirect ? <Redirect push to={this.state.status.next} /> : null} 
            </Card>
        </form>
      );
    }
  }

export default LoginForm;