import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function RadioButtonsGroup(props) {
  const [value, setValue] = React.useState('estudiante');

  const handleChange = (event) => {
    setValue(event.target.value);
    props.change(event);
    };

  return (
    <FormControl  component="fieldset">
      <FormLabel component="legend">Seleccione un estamento</FormLabel>
      <RadioGroup aria-label="estamento" name="type" value={value} onChange={handleChange}>
        <FormControlLabel value="estudiante" control={<Radio />} label="Estudiante" />
        <FormControlLabel value="docente" control={<Radio />} label="Docente" />
        <FormControlLabel value="administrativo" control={<Radio />} label="Administrativo" />
      </RadioGroup>
    </FormControl>
  );
}


