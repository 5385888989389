import React from 'react';
import Typography from '@material-ui/core/Typography';
import AdminLoginForm from "views/Admin/AdminLoginForm";

class AdminLoginPage extends React.Component{

  render(){
  return (
    <div>
      <Typography variant="h2"   gutterBottom>
          Administración
      </Typography>
      <Typography variant="h6" gutterBottom>
          Ingrese con sus datos si es Administrador
      </Typography>
      <AdminLoginForm login={this.props.login} darkState={this.props.darkState} />
    </div>
  );}
}
export default AdminLoginPage;