import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExitToApp from '@material-ui/icons/ExitToApp';

import UsuariosDataService from "services/usuarios.service";
import Fab from '@material-ui/core/Fab';

import {Redirect} from 'react-router-dom';
const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});

class LogoutButton extends React.Component {
  
  constructor(props){
    super(props);
    this.state = { "status": {"show":false,"inSession":props.getLogin(), "msg": "", "status":"success", "next":window.location.pathname === "/admin" ? "/admin" : "/"}};
  }
  handleSubmit = (event) => {
    UsuariosDataService.logout()
    .then(response => {
      if (response.data.code === 1){
        let statusCopy = Object.assign({}, this.state);
        statusCopy.status.show= true;
        statusCopy.status.msg = response.data.message;
        statusCopy.status.status = "success";
        statusCopy.status.inSession = false;
        this.setState(statusCopy)
        UsuariosDataService.clearSession();
        this.props.logout();
      }else{
        let statusCopy = Object.assign({}, this.state);
        statusCopy.status.show= true;
        statusCopy.status.msg = response.data.message;
        statusCopy.status.status = "error";
        statusCopy.status.inSession = false;
        this.setState(statusCopy);
        UsuariosDataService.clearSession();
        this.props.logout();
      }
    })
    .catch(e => {
      if (e.response.status === 401){
        let statusCopy = Object.assign({}, this.state);
        statusCopy.status.show= true;
        statusCopy.status.msg = e.response.data.message;
        statusCopy.status.status = "error";
        statusCopy.status.inSession = false;
        this.setState(statusCopy);
        UsuariosDataService.clearSession();
        this.props.logout();
      }
      console.log(e);
    })
    event.preventDefault();
  }
  render(){
    const { classes } = this.props;

    if (this.props.getLogin() === false){
      return <Redirect push to={this.state.status.next} />
    }

    return( 
      <form onSubmit={this.handleSubmit}>
      <Fab
          variant="extended"
          size="medium"
          color="secondary"
          aria-label="add"
          className={classes.margin}
          type="submit"
        >
          <ExitToApp className={classes.extendedIcon}/>
          Salir
        </Fab>
      </form>
    )
  }
}


export default withStyles(styles)(LogoutButton);