import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Send from '@material-ui/icons/Send';

import Snackbar from "components/Snackbars/Snackbar";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';

import InputAdornment from '@material-ui/core/InputAdornment';
import Home from '@material-ui/icons/Home';
import Phone from '@material-ui/icons/Phone';
import PhoneAndroid from '@material-ui/icons/PhoneAndroid';
import Email from '@material-ui/icons/Email';
import PhoneMask from "components/Masks/PhoneMask"
import CellphoneMask from "components/Masks/CellPhoneMask"
import EmailMask from "components/Masks/EmailMask"
import analyze from "components/Password/analyze";
import UsuariosDataService from "services/usuarios.service";


import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class ActivateAccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { "givenName": "", "lastName": "", "username": "", "form": { "changePassword": true, "password": "", "verificationPassword": "" , "phone": "", "cellphone": "", "direction": "", "email": "" }, "redirect": false, "next": "/", "showToast": false, "msg": "", "status": "success", "loading": false };
    this.getUser();
  }

  getUser = () => {
    UsuariosDataService.getUser()
    .then(response => {
      try {
        if (response.data.code === 1){
          var user = response.data.data;
          let statusCopy = Object.assign({}, this.state);
          statusCopy.form.direction = user.direction;
          statusCopy.form.phone = user.phone;
          statusCopy.form.cellphone = user.cellphone;
          statusCopy.form.email = user.email;
          this.setState({ "form": statusCopy.form });
          this.setState({"givenName": user.givenName});
          this.setState({"lastName": user.lastName});
          this.setState({"username": user.username});
        }
        this.setState(
          {"show": response.data.show , "showButton":response.data.showButton, "text": response.data.message, "code":response.data.code }
          );
      } catch (error) {
        console.log(error)
      }
    })
    .catch(e => {
      if (e.response.status === 401){
        console.log(e);
        let statusCopy = Object.assign({}, this.state);
        statusCopy.redirect = true;
        statusCopy.next = "/";
        this.setState(statusCopy);
      }
    });
  }

  handleClearSnack = (event) => {
    this.setState({ "showToast": false });
    this.setState({ "msg": "" });
    this.setState({ "status": "success" });
  }

  handleChange = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
  }
  handleChangePass = (event) => {
    if (this.state.form.changePassword === false) {
      return;
    }
    var status = analyze(event.target.value);
    if (status.result === false) {
      if (status.number === false) {
        this.setState({ "showToast": true });
        this.setState({ "msg": "Debe tener almenos un número" });
        this.setState({ "status": "error" });
      } else {
        if (status.lower === false) {
          this.setState({ "showToast": true });
          this.setState({ "msg": "Debe tener almenos una letra en minúscula" });
          this.setState({ "status": "error" });
        } else {
          if (status.upper === false) {
            this.setState({ "showToast": true });
            this.setState({ "msg": "Debe tener almenos una letra en mayúscula" });
            this.setState({ "status": "error" });
          } else {
            if (status.length === false) {
              this.setState({ "showToast": true });
              this.setState({ "msg": "Debe tener como mínimo 8 caracteres" });
              this.setState({ "status": "error" });
            }
          }
        }
      }
    }
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
    if (status.result) {
      if (this.state.form.password !== this.state.form.verificationPassword) {
        this.setState({ "showToast": true });
        this.setState({ "msg": "Las contraseñas no coinciden" });
        this.setState({ "status": "error" });
      } else {
        this.setState({ "showToast": true });
        this.setState({ "msg": "Las contraseñas coinciden" });
        this.setState({ "status": "success" });
      }
    }
  }
  handleChangeSwitch = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.checked
    if (event.target.checked === false) {
      statusCopy.form.password = ""
      statusCopy.form.verificationPassword = ""
    }
    this.setState({ "form": statusCopy.form });
  }

  handleSubmit = (event) => {
    this.handleClearSnack(event);
    var canSend = true;
    if (this.state.form.changePassword){
      if (this.state.form.password !== this.state.form.verificationPassword) {
        this.setState({ "showToast": true });
        this.setState({ "msg": "Las contraseñas no coinciden" });
        this.setState({ "status": "error" });
        canSend = false;
      }else{
        canSend = true;
      }
    }
    if (canSend){
      var data = JSON.stringify(this.state.form);
      this.setState({"loading": true})
      UsuariosDataService.activateAccount(data)
      .then(response => {
        if (response.data.code === 1){
          this.setState({ "showToast": true });
          this.setState({ "msg": response.data.message });
          this.setState({ "status": "success" });
          this.setState({ "next": "/success"});
          this.setState({ "redirect": true});
        }else{
          this.setState({ "showToast": true });
          this.setState({ "msg": response.data.message });
          this.setState({ "status": "error" });
        }
        this.setState({"loading": false})
      })
      .catch(e => {
        this.setState({"loading": false})
        console.log(e);
        if (e.response.status === 401){
          this.setState({ "showToast": true });
          this.setState({ "msg": e.response.data.message });
          this.setState({ "status": "success" });
          this.setState({ "redirect": true});
          this.setState({ "next": "/"});
        }else{
          try {
            this.setState({ "msg": JSON.stringify(e.response.data.message[Object.keys(e.response.data.message)[0]]) });
            this.setState({ "showToast": true });
            this.setState({ "status": "error" });
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
    event.preventDefault();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.next} />
    }

    return (
      <div>
        <Accordion expanded >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >Nombres</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6" align="justify"  >
              Usuario: {this.state.username}
              <br />
              Nombres: {this.state.givenName}
              <br />
              Apellidos: {this.state.lastName}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography >Información Personal</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField onChange={this.handleChange} name="direction" fullWidth id="standard-direction" label="Dirección" variant="outlined" required
                  value={this.state.form.direction}
                  helperText="Dirección donde se encuentra el domicilio"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Home />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 70 }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField onChange={this.handleChange} name="phone" fullWidth id="standard-phone" label="Telefono" variant="outlined"
                  value={this.state.form.phone}
                  helperText="Número Telefónico fijo"
                  InputProps={
                    {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                      inputComponent: PhoneMask
                    }
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField onChange={this.handleChange} name="cellphone" fullWidth id="standard-cellphone" label="Celular" variant="outlined" required
                  value={this.state.form.cellphone}
                  helperText="Número Telefónico de celular"
                  InputProps={
                    {
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneAndroid />
                        </InputAdornment>
                      ),
                      inputComponent: CellphoneMask
                    }
                  }
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField onChange={this.handleChange} name="email" fullWidth id="standard-email" label="Email" variant="outlined" required
                  value={this.state.form.email}
                  helperText="Dirección de correo electrónico"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                    inputComponent: EmailMask
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.form.changePassword} >
          <AccordionSummary

            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.form.changePassword}
                    name="changePassword"
                    disabled
                  />
                }
                label="Cambiar Contraseña" />
            </FormGroup>
          </AccordionSummary>
          <AccordionDetails>

            <br></br>
            {this.state.form.changePassword &&
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField onChange={this.handleChangePass} name="password" fullWidth id="password" label="Nueva Contraseña" variant="outlined"
                    helperText="Contraseña de la cuenta Institucional" type="password" inputProps={{ autoComplete: "new-password" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField onChange={this.handleChangePass} name="verificationPassword" fullWidth id="verificationPassword" label="Repetir Contraseña " variant="outlined"
                    helperText="Contraseña de la cuenta Institucional" type="password" inputProps={{ autoComplete: "new-password" }}
                  />
                </Grid>
              </Grid>

            }
          </AccordionDetails>
        </Accordion>

        <br />

        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Card elevation={3}>

            <CardActions>
              <Button fullWidth
                disabled={this.state.loading}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<Send />}
              >
                ACTIVAR
                </Button>
                {this.state.loading && <CircularProgress size={24} />}
            </CardActions>
            {this.state.showToast && <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} />}
          </Card>
        </form>

      </div>
    );
  }
}

export default ActivateAccountForm;