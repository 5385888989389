import React from 'react';
import Snackbar from "components/Snackbars/Snackbar";
import { Button, Card, CardActions,  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import UsuariosDataService from "services/usuarios.service";
class ForgotPasswordForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {"open": false, "email": "", "show": false, "msg": "", "status": "success"}
    UsuariosDataService.getRecoverEmail()
      .then(response => {
        try {
          if (response.data.code === 1){
            this.setState({"email": response.data.email} );
          }
        } catch (error) {
          console.log(error)
        }
      })
      .catch(e => {
          console.log(e);
      });
  }
  handleCloseDialog = () => {
    this.setState({"open": false})
  }
  handleOpenDialog = () => {
    this.setState({"open": true})
  }
  handleClearSnack = (event) => {
    this.setState({ "show": false });
    this.setState({ "msg": "" });
    this.setState({ "status": "success" });
  }
  handleConfirmDialog = () => {
    this.handleCloseDialog();
    UsuariosDataService.recoverAccount()
      .then(response => {
        try {
            this.setState({ "show": true });
            this.setState({ "msg": response.data.message });
            this.setState({ "status": response.data.code === 1 ? "success" : "error" })
        } catch (error) {
          console.log(error)
        }
      })
      .catch(e => {
          console.log(e);
      });
  }
  render() {
    return (
      <Card elevation={3}>
        
        <CardActions>
          <Button fullWidth
            variant="text"
            color="secondary"
            onClick={this.handleOpenDialog}
          >
            Olvide mi contraseña
              </Button>
        </CardActions>
        <Dialog
              open={this.state.open}
              onClose={this.handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"Desea recuperar su contraseña?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Se va enviar un correo de recuperación a su direccion: "+this.state.email}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleConfirmDialog} color="primary" >
                  Aceptar
                </Button>
                <Button onClick={this.handleCloseDialog} color="secondary" autoFocus>
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
            {this.state.show && <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} />}
      </Card>
    );
  }
}

export default ForgotPasswordForm;