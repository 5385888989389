import http from "../http-common";
import  {isValid, parseISO} from 'date-fns'
class UsuariosDataService{
    login(data){
        return http.post("/api/login",data);
    }
    verifyRu(data){
        return http.post("/api/verification/ru",data,this.getAuthToken());
    }
    verifyBirthday(data){
        return http.post("/api/verification/birthday",data,this.getAuthToken());
    }
    verifyResponsable(data){
        return http.post("/api/verification/responsable",data,this.getAuthToken());
    }
    getverifyAccount(){
        return http.get("/api/verify",this.getAuthToken());
    }
    verifyAccount(data){
        return http.post("/api/verify",data,this.getAuthToken());
    }
    editAccount(data){
        return http.post("/api/edit",data,this.getAuthToken());
    }
    activateAccount(data){
        return http.post("/api/activate",data,this.getAuthToken());
    }
    statusGsuit(){
        return http.post("/api/gsuit/status",{},this.getAuthToken());
    }
    migrateGsuit(){
        return http.post("/api/gsuit/migrate",{},this.getAuthToken());
    }
    getUsername(){
        return http.get("/api/username",this.getAuthToken());
    }
    getRecoverEmail(){
        return http.get("/api/recover",this.getAuthToken());
    }
    recoverAccount(){
        return http.post("/api/recover",{},this.getAuthToken());
    }
    getUser(){
        return http.get("/api/user",this.getAuthToken());
    }
    getNewOTP(){
        return http.get("/api/otp",this.getAuthToken());
    }
    setNewOTP(data){
        return http.post("/api/otp",data,this.getAuthToken());
    }
    deleteNewOTP(data){
        return http.patch("/api/otp",data,this.getAuthToken()); 
    }
    logout(){
        return http.post("/api/logout",{},this.getAuthToken());
    }
    getSuccessAction(){
        return http.get("/api/success",this.getAuthToken());    
    }
    clearSession(){
        localStorage.removeItem("currentUserToken");
        localStorage.removeItem("currentUserExpires");
    }
    getAuthToken(callbackExpire=undefined){

        var defaultConf = {
            headers: {
                "Content-type": "application/json"
            }
        }

        var token = localStorage.getItem('currentUserToken');
        var expires = localStorage.getItem('currentUserExpires');
        if (expires === null || expires === undefined){
            return defaultConf
        }
        var expireDate = parseISO(expires)
        var validTime = isValid(expireDate)
        if (validTime && Date.now() <= expireDate.getTime()){
            return {
                headers: {
                    "Content-type": "application/json",
                    'Authorization': `Bearer ${token}`
                }
            }
        }else{
            if (callbackExpire !== undefined)
                callbackExpire();
            this.clearSession();
            
            return defaultConf;
        }
    }
    isLoggedIn(){
        var expires = localStorage.getItem('currentUserExpires');
        if (expires === null || expires === undefined){
            return false
        }
        var expireDate = parseISO(expires)
        var validTime = isValid(expireDate)
        if (validTime && Date.now() >= expireDate.getTime()){
            this.clearSession();
            return false;
        }else{
            return true;
        }
    }
}
export default new UsuariosDataService();