import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import UsuariosDataService from "services/usuarios.service";
import red from '@material-ui/core/colors/red';

import {Redirect} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ItemGrid from "views/Google/ItemGridGoogleProduct";
import logoGoogle from 'assets/img/google.svg';
import logoGoogleServices from 'assets/img/google/gsuit.gif';
import logoGmail from 'assets/img/google/gmail96.png';
import logodrive from 'assets/img/google/drive96.png';
import logoCalendar from 'assets/img/google/calendar96.png';
import logoDocs from 'assets/img/google/docs.png';
import logoJamboard from 'assets/img/google/jamboard.png';
import logoMeet from 'assets/img/google/meet96.png';
// https://www.gstatic.com/images/branding/product/2x/hh_{s}_96dp.png
import Snackbar from "components/Snackbars/Snackbar";

import Zoom from '@material-ui/core/Zoom';
class MigrationStatePage extends React.Component{
  constructor(props){
    super(props)
    this.state = {"show": false, "showButton":false, "text": "prueba", "code":0, "redirect" :false, "next": "/", "open":false , "message" : "", "showMessage": false, "showMessageStatus": "success" }
    this.stateButton();
  }

  stateButton = () => {
    UsuariosDataService.statusGsuit()
    .then(response => {
      try {
        this.setState(
          {"show": response.data.show, "showButton":response.data.showButton, "text": response.data.message, "code":response.data.code }
          );
      } catch (error) {
        console.log(error)
      }
    })
    .catch(e => {
      if (e.response.status === 401){
        console.log(e);
        let statusCopy = Object.assign({}, this.state);
        statusCopy.redirect = true;
        statusCopy.next = "/";
        this.setState(statusCopy);
      }
    });
  }

  handleClickOpen = (event) => {
    this.setState({"open":true})
  }
  handleClickClose = (event) => {
    this.setState({"open":false})
  }
  handleMigrate = (event) => {
    this.setState({"open":false})
    UsuariosDataService.migrateGsuit()
    .then(response => {
      try {
        if (response.data.code === 1){
          let statusCopy = Object.assign({}, this.state);
          statusCopy.redirect = true;
          statusCopy.next = "/success";
          this.setState(statusCopy);
        }else{
          this.setState({"message": response.data.message} );
          this.setState({"showMessage": true});
          this.setState({"showMessageStatus": "error"});
        }
        this.stateButton();
      } catch (error) {
        console.log(error)
      }
    })
    .catch(e => {
      if (e.response.status === 401){
        console.log(e);
        let statusCopy = Object.assign({}, this.state);
        statusCopy.redirect = true;
        statusCopy.next = "/";
        this.setState(statusCopy);
      }
    });
  }
  handleClearSnack = (event) =>{
    this.setState({"showMessage": false});
  }
  

  render(){
    if (this.state.redirect){
      return <Redirect push to={this.state.next} />
    }

    if (this.state.show === false){
      return(
        <div></div>
      )
    }
  return (
    <div>
      <Zoom   in={this.state.show} >
        
       <Card elevation={3} style={this.state.code !== 1 && {  backgroundColor: red[200] }}>
                <CardContent>
                <Grid container wrap="nowrap" spacing={3} alignItems="center"  >
                  <Grid item>
                    {
                      this.state.code === 1 && !this.state.showButton ?
                      <img src={logoGoogleServices} className="App-logo-left" alt="logo" />
                      :
                      <img src={logoGoogle} className="App-logo-left" alt="logo" />
                    }
                  </Grid>
                  <Grid item >
                    <Typography variant="h6" >{this.state.text}</Typography>
                  </Grid>
                </Grid>
                
                  
                </CardContent>
                
                
                  <CardActions>
                    <Button 
                    variant="contained"
                    color="primary"
                    onClick={this.handleClickOpen}
                    >
                    Servicios
                    </Button>

                    <Dialog fullWidth={true} maxWidth="md" 
                      open={this.state.open}
                      onClose={this.handleClickClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{
                        this.state.code === 1 ? (
                          this.state.showButton ? "Migremos hacia Google For Education":"Descubre los servicios de Google For Education"
                          ): "Migremos hacia Google For Education"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Nos preparamos para dar el salto hacia servicios más eficientes.
                        </DialogContentText>
                        <Grid container spacing={1}  alignItems="baseline">
                          <Grid item xs={12} sm={6} lg={6}> 
                            <ItemGrid 
                            ItemTitle={"Gmail"} 
                            ItemContent={"Un sistema de correo electrónico más seguro y eficiente para toda la universidad. Integrado con nuestra cuenta institucional. "}
                            ItemLink={"http://mail.google.com"}
                            ItemIcon={logoGmail}/>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}> 
                            <ItemGrid
                            ItemTitle={"Meet"}
                            ItemContent={"Conecta de forma segura, colabora y reúnete desde cualquier lugar. Crea y unete a videollamadas de gran calidad para grupos"}
                            ItemLink={"http://meet.google.com"}
                            ItemIcon={logoMeet}/>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}> 
                            <ItemGrid 
                            ItemTitle={"Drive"} 
                            ItemContent={"Almacenar, organizar documentos y acceder desde cualquier dispositivo. Cada usuario contará con 30GB de almacenamiento."}
                            ItemLink={"http://drive.google.com"}
                            ItemIcon={logodrive}/>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}> 
                            <ItemGrid
                            ItemTitle={"Calendario"}
                            ItemContent={"Un calendario integrado para poder gestionar tareas y horarios con mayor facilidad. Disponible desde cualquier dispositivo."}
                            ItemLink={"http://calendar.google.com"}
                            ItemIcon={logoCalendar}/>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}> 
                            <ItemGrid 
                            ItemTitle={"Docs, Sheets & Presentation"} 
                            ItemContent={"Trabaja en colaboración y en tiempo real con documentos, hojas de cálculo, y presentaciones."}
                            ItemLink={"http://docs.google.com"}
                            ItemIcon={logoDocs}/>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}> 
                            <ItemGrid
                            ItemTitle={"Jamboard"}
                            ItemContent={"Dibuja y colabora en un lienzo interactivo, una pizarra inteligente para utilizar desde una computadora, teléfono o Tablet."}
                            ItemIcon={logoJamboard}
                            ItemLink={"http://jamboard.google.com"}
                            />
                            
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12}> 
                            <ItemGrid
                            ItemTitle={"Y mucho más..."}
                            ItemContent={this.state.code === 1 && this.state.showButton ?
                              "Para acceder a estos servicios y muchos más realice la migración haciendo clic en el botón de Aceptar.":
                              "Para acceder a estos servicios haga en clic en las imágenes y acceda con su cuenta Institucional."}
                            ItemIcon={logoGoogle}
                            ItemLink={null}
                            ItemFooter={"UMSA ❤ Google."}/>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClickClose} color="primary">
                          Cerrar
                        </Button>
                        {this.state.showButton ? 
                        <Button onClick={this.handleMigrate} color="primary" autoFocus>
                          Migrar
                        </Button>
                        : null} 
                      </DialogActions>
                    </Dialog>

                  </CardActions>

                
                
                
                  {this.state.showMessage ? <Snackbar msg={this.state.message} status={this.state.showMessageStatus} clear={this.handleClearSnack}/> : null} 
            </Card>
      </Zoom  >
    </div>
  )
}
}
export default MigrationStatePage;