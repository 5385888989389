import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { Switch, Route } from 'react-router-dom';
// import { NavLink, Switch, Route } from 'react-router-dom';


import LoginPage from 'views/Login/LoginPage';
import VerificationRuPage from 'views/Verification/VerificationRuPage';
import VerificationBirthdayPage from 'views/Verification/VerificationBirthdayPage';
import VerificationResponsablePage from 'views/Verification/VerificationResponsablePage';
import VerifyPasswordPage from "views/Accounts/VerifyPasswordPage";
import EditAccountPage from "views/Accounts/EditAccountPage";
import ActivateAccountPage from "views/Accounts/ActivateAccountPage";
import SuccessPage from "views/Accounts/SuccessPage";
import logoApp from 'assets/img/usuarios-logo-name.svg';
import LogoutButton from "views/utils/LogoutButton";
import UsuariosDataService from "../services/usuarios.service";
import AdminLoginPage from "views/Admin/AdminLoginPage";
import AdminDashboardPage from "views/Admin/AdminDashboardPage";
import { Fab, Typography } from '@material-ui/core';
import NetworkDetector from "views/NetworkDetector";
import NotFound from 'assets/img/404.svg';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
    "pointer-events": "none",
    width: "40px",
    height: "40px"
  },
  appbar: {
    alignItems: 'center',
  }
});

class ElevateAppBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { "logged": UsuariosDataService.isLoggedIn() }
    console.log(props)
  }

  logout = () => {
    this.setState({ "logged": false })
  }

  getlogin = () => {
    return this.state.logged;
  }

  login = () => {
    this.setState({ "logged": true })
  }



  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <ElevationScroll {...this.props}>
          <AppBar>
            <Toolbar>
              <Fab
                size="medium"
                color="primary"
                aria-label="theme"
                type="button"
                onClick={this.props.toogleTheme}
              >
                {this.props.getActualThemeIsDark() ? <BrightnessHighIcon/> :<Brightness4Icon/> }
              </Fab>
              <img src={logoApp} className={classes.title} alt="logo" />

              <LogoutButton logout={this.logout} getLogin={this.getlogin} />
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
        <Main login={this.login} logout={this.logout}  darkState={this.props.darkState} getActualThemeIsDark={this.props.getActualThemeIsDark}/>

      </React.Fragment>
    )
  }
}


// const Navigation = () => (
//     <nav>
//       <ul>
//         <li><NavLink exact activeClassName="current" to='/'>Home</NavLink></li>
//         <li><NavLink exact activeClassName="current" to='/about'>About</NavLink></li>
//         <li><NavLink exact activeClassName="current" to='/contact'>Contact</NavLink></li>
//       </ul>
//     </nav>
//   );


class Main extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path='/' render={(props) => (
          <Container maxWidth="md">
            <Box my={3}>
              <LoginPage {...props} login={this.props.login} logout={this.props.logout} darkState={this.props.darkState}/>
            </Box>
          </Container>
        )}></Route>
        <Route exact path='/verification/ru' component={VerifyRU}></Route>
        <Route exact path='/verification/birthday' component={VerifyBirthday}></Route>
        <Route exact path='/verification/responsable' component={VerifyResponsable}></Route>
        <Route exact path='/verify' component={VerifyPassword}></Route>
        <Route exact path='/edit' component={EditAccount}></Route>
        <Route exact path='/activate' component={ActivateAccount}></Route>
        <Route exact path='/success' render={
          (props) => (
            <SuccessAction logout={this.props.logout} />
          )
        }></Route>
        <Route exact path='/admin' render={(props) => (
          <Container maxWidth="md">
            <Box my={3}>
              <AdminLoginPage {...props} login={this.props.login} darkState={this.props.darkState} />
            </Box>
          </Container>
        )}></Route>
        <Route exact path='/admin/dashboard' render={(props) => (
          <AdminDashboard {...props} login={this.props.login} logout={this.props.logout} />
        )
        } ></Route>
        <Route component={FourOhFour} />
      </Switch>
    );
  }
}
const useStyles = makeStyles({
  error: {
    width: "50%"
  }
});
const FourOhFour = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h2" gutterBottom>Lo sentimos!</Typography>
      <Typography variant="h5" gutterBottom>Página no encontrada</Typography>
      <img src={NotFound} alt="success" className={classes.error} />
    </div>
  )
};

// class Home extends React.Component {
//   constructor(props){
//     super(props);
//   }
//   render(){
//     return (
//       <LoginPage login={this.props.login}/>
//     );
//   }
// }



// const Home = () => (

// );

const VerifyRU = () => (
  <Container maxWidth="md">
    <Box my={3}>
      <VerificationRuPage />
    </Box>
  </Container>
);

const VerifyBirthday = () => (
  <Container maxWidth="md">
    <Box my={3}>
      <VerificationBirthdayPage />
    </Box>
  </Container>
);
const VerifyResponsable = () => (
  <Container maxWidth="md">
    <Box my={3}>
      <VerificationResponsablePage />
    </Box>
  </Container>
);

const VerifyPassword = () => (
  <Container maxWidth="md">
    <Box my={3}>
      <VerifyPasswordPage />
    </Box>
  </Container>
);

const EditAccount = () => (
  <Container maxWidth="md">
    <Box my={3}>
      <EditAccountPage />
    </Box>
  </Container>
);
const ActivateAccount = () => (
  <Container maxWidth="md">
    <Box my={3}>
      <ActivateAccountPage />
    </Box>
  </Container>
);
const SuccessAction = (props) => (
  <Container maxWidth="md">
    <Box my={3}>
      <SuccessPage logout={props.logout} />
    </Box>
  </Container>
);

const AdminDashboard = (props) => (
  <AdminDashboardPage login={props.login} logout={props.logout} />
);

// const About = () => (
//   <div className='about'>

//     <p>Ipsum dolor dolorem consectetur est velit fugiat. Dolorem provident corporis fuga saepe distinctio ipsam? Et quos harum excepturi dolorum molestias?</p>
//     <p>Ipsum dolor dolorem consectetur est velit fugiat. Dolorem provident corporis fuga saepe distinctio ipsam? Et quos harum excepturi dolorum molestias?</p>
//   </div>
// );

// const Contact = () => (
//   <div className='contact'>
//     <h1>Contact Me</h1>
//     <p>You can reach me via email: <strong>hello@example.com</strong></p>
//   </div>
// );

export default NetworkDetector(withStyles(styles)(ElevateAppBar))