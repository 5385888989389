import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from "@material-ui/core/TextField";

import Divider from '@material-ui/core/Divider';
import AdminDataService from "../../services/admin.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "components/Snackbars/Snackbar";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionActions, Box, Button, ButtonGroup, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, Input, InputLabel, List, ListItem, ListItemText, MenuItem, Select } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { green, red, yellow } from '@material-ui/core/colors';
import ReplayIcon from '@material-ui/icons/Replay';
import AddIcon from '@material-ui/icons/Add';
import UsernameMask from "components/Masks/UsernameMask"
import { es } from "date-fns/locale";
import format from 'date-fns/format'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import ChipInput from 'material-ui-chip-input'
const useStyles = theme => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  wrapText: {
    wordWrap: "break-word"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
});

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);

    const actual = new Date()
    actual.setFullYear(actual.getFullYear() - 18)
    this.state = {
      "form": { "search": "", "unique": false }, "loading": false, "show": false, "msg": "", "status": "success",
      "open": false, "indexDialog": -1, "data": [], "fulldetails": false, "gsuit": {},
      "canGsuit": props.permissions.includes("list-google"),
      "canReset": props.permissions.includes("reset-ad"),
      "canEditInstitucionalAccount": props.permissions.includes("institucional-account-edit"),
      "canSignOutGsuit": props.permissions.includes("signout-google"),
      "canCrudAccounts": props.permissions.includes("crud-ad"),
      "openUID": false,
      "openSignOut": false,
      "openCreate": false,
      "accountForm": this.defaultValueAccountForm(),
      "date": actual,
      "dateDisable": actual,
      "isInstitucional": false,
      "dataGroups": [],
      "edit": false,
      "groups": [],
      "onEditGroupDialog": false,
      "responsables": [],
      "onEditGroupIndex": -1,
      "onEditGroupObject": {
        "id": "", "username": "", "init": 0, "end": 0, "current": true, "group": ""
      },
      "idGroupDelete": "",
      "openGroupDelete": false,
      "usernameGroupDelete": "",
      "reset2fa": false,
      "searchOpen": false,
      "filter": {
        "sAMAccountName": "",
        "givenName": "",
        "ci": "",
        "snPaternal": "",
        "snMaternal": "",
        "sn": ""
      },
      "observations": []
    }
    if (this.state.canCrudAccounts) {
      this.handleLoadDataGroups()
    }
    if (this.state.observations) {
      this.handleOpenObservations()
    }
  }
  defaultValueAccountForm = () => {
    const actual = new Date()
    actual.setFullYear(actual.getFullYear() - 18)
    return {
      "username": "",
      "familyname": "",
      "snPaternal": "",
      "snMaternal": "",
      "date": format(actual, "dd/MM/yyyy"),
      "type": [AdminDataService.listEstamentoArray()[0]],
      "accountexpire": actual.getTime(),
      "member": [],
      "responsable": [],
      "observations": ""    
    }
  }
  setDefaultAccountForm = () => {
    const actual = new Date()
    actual.setFullYear(actual.getFullYear() - 18)
    this.setState({ "accountForm": this.defaultValueAccountForm() });
    this.setState({ "date": actual })
    this.setState({ "dateDisable": actual })
    this.setState({ "isInstitucional": false })
  }

  handleLoadDataGroups = () => {
    AdminDataService.listGroups()
      .then(response => {
        if (response.data.code === 1) {
          this.setState({ "dataGroups": response.data.data });
        }
      })
      .catch(e => {
        console.log(e);
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            this.setState({ "show": true })
            this.setState({ "msg": e.response.data.message })
            this.setState({ "status": "error" })
            AdminDataService.logout();
          }
        } catch (error) {
          console.log(error)
        }
      })
  }
  handleClearSnack = (event) => {
    this.setState({ "show": false })
  }

  handleChange = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
  }

  handleChangeFilters = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.filter[event.target.name] = event.target.value
    this.setState({ "filter": statusCopy.filter });
  }
  handleChangeSwitchFilterContain = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form["unique"] = event.target.checked
    this.setState({ "form": statusCopy.form });
  }

  handleSubmitFilters = (event) => {
    let query = "";
    if (this.state.filter.sAMAccountName !== "") {
      query += `sAMAccountName:${this.state.filter.sAMAccountName} `;
    }
    if (this.state.filter.ci !== "") {
      query += `uid:${this.state.filter.ci} `;
    }
    if (this.state.filter.givenName !== "") {
      query += `givenName:${this.state.filter.givenName} `;
    }
    if (this.state.filter.snPaternal !== "") {
      query += `snPaternal:${this.state.filter.snPaternal} `;
    }
    if (this.state.filter.snMaternal !== "") {
      query += `snMaternal:${this.state.filter.snMaternal} `;
    }
    if (this.state.filter.sn !== "") {
      query += `sn:${this.state.filter.sn} `;
    }
    if (query !== "") {
      let statusCopy = Object.assign({}, this.state);
      statusCopy.form["search"] = query
      this.setState({ "filter": statusCopy.filter });
    }
    this.handleCloseSearchDialog()
    this.handleSubmit(event)
  }

  searchInsertedOrEdited = (username) => {
    this.handleChange({ target: { "name": "search", "value": username } })
    try {
      this.handleSubmit(undefined);
    } catch (error) {
    }
  }
  handleChangeCrud = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.accountForm[event.target.name] = event.target.value
    if (event.target.name === "snPaternal") {
      if ("snMaternal" in statusCopy.accountForm) {
        let maternal = statusCopy.accountForm["snMaternal"]
        maternal = maternal.trim()
        let actual = event.target.value.trim()
        statusCopy.accountForm["familyname"] = actual + (maternal.length > 0 ? (actual.length > 0 ? ` ${maternal}` : maternal) : "")
      } else {
        // @ts-ignore
        statusCopy.accountForm["familyname"] = event.target.value.trim()
      }
    }
    if (event.target.name === "snMaternal") {
      if ("snPaternal" in statusCopy.accountForm) {
        let paternal = statusCopy.accountForm["snPaternal"]
        paternal = paternal.trim()
        let actual = event.target.value.trim()
        // @ts-ignore
        statusCopy.accountForm["familyname"] = (paternal.length > 0 ? (actual.length > 0 ? `${paternal} ` : paternal) : "") + event.target.value.trim()
      } else {
        // @ts-ignore
        statusCopy.accountForm["familyname"] = event.target.value.trim()
      }
    }

    if (event.target.name === "type") {
      const exist = event.target.value.includes("institucional");
      this.setState({ "isInstitucional": exist })
      if (!exist) {
        delete statusCopy.accountForm["responsable"]
      }
    }   
    this.setState({ "accountForm": statusCopy.accountForm });
  }

  handleSubmit = (event) => {
    this.setState({ "loading": true })
    var data = JSON.stringify(this.state.form);
    AdminDataService.listUsers(data).then(response => {
      if (response.data.code === 1) {
        this.setState({ "data": response.data.data });
        this.setState({ "gsuit": {} })
        this.setState({ "groups": {} })
      } else {
      }
      this.setState({ "loading": false })
    })
      .catch(e => {
        this.setState({ "loading": false })
        console.log(e);
        try {
          if (e.response.status === 403) {
            AdminDataService.logout();
            this.setState({ "show": true })
            this.setState({ "msg": e.response.data.message })
            this.setState({ "status": "error" })
          }
          if (e.response.status === 401) {
            AdminDataService.logout();
            this.setState({ "show": true })
            this.setState({ "msg": e.response.data.message })
            this.setState({ "status": "error" })
          }
        } catch (error) {
          console.log(error)
        }
      })
    event.preventDefault();
  }
  handleChangeSwitch = (event) => {
    this.setState({ "fulldetails": event.target.checked });
  }
  handleChangeSwitch2FAReset = (event) => {
    this.setState({ "reset2fa": event.target.checked });
  }

  handleSubmitGsuit = (index, username) => {
    var data = JSON.stringify({ "search": username });
    AdminDataService.listGUsers(data).then(response => {
      if (response.data.code === 1) {
        let gsuit = Object.assign({}, this.state.gsuit);
        gsuit[index] = {
          "exist": response.data.data !== null,
          "data": response.data.data
        }

        this.setState({ "gsuit": gsuit });
      }
    })
      .catch(e => {
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
          }
        } catch (error) {
          console.log(error)
        }
      })
  }
  handleChangeGroup = (event) => {
    let group = Object.assign({}, this.state.onEditGroupObject);
    group[event.target.name] = event.target.value
    this.setState({ "onEditGroupObject": group });
  }
  handleChangeCurrentGroup = (event) => {
    let group = Object.assign({}, this.state.onEditGroupObject);
    group[event.target.name] = event.target.checked
    this.setState({ "onEditGroupObject": group });
  }
  handleOpenGroups = (index, username) => {
    AdminDataService.listUserGroups({ "username": username }).then(response => {
      if (response.data.code === 1) {
        let groups = Object.assign({}, this.state.groups);
        groups[index] = {
          "exist": response.data.data.length !== 0,
          "data": response.data.data
        }
        this.setState({ "groups": groups });
      }
    })
      .catch(e => {
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
          }
        } catch (error) {
          console.log(error)
        }
      })
  }
  handleOpenResponsable = (index, username) => {
    AdminDataService.listUserResponsables({ "username": username }).then(response => {
      if (response.data.code === 1) {
        let responsables = Object.assign({}, this.state.responsables);
        responsables[index] = {
          "exist": response.data.data.length !== 0,
          "data": response.data.data
        }
        this.setState({ "responsables": responsables });
      }
    })
      .catch(e => {
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
          }
        } catch (error) {
          console.log(error)
        }
      })
  }
  handleOpenDialogGroupsEdit = (index, username, oid) => {
    console.log(username)
    AdminDataService.listUserGroups({ "id": oid }).then(response => {
      if (response.data.code === 1) {
        this.setState({ "onEditGroupIndex": index })
        let group = {
          "id": response.data.data._id.$oid,
          "username": response.data.data.username,
          "current": response.data.data.current,
          "init": response.data.data.init.$date,
          "end": response.data.data.init.$date,
          "group": response.data.data.group
        }
        this.setState({ "onEditGroupObject": group })
        this.setState({ "onEditGroupDialog": true })
      }
    })
      .catch(e => {
        console.log(e);
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
          }
        } catch (error) {
          console.log(error)
        }
      })
  }
  handleCloseDialogEditGroup = () => {
    this.setState({ "onEditGroupDialog": false })
  }

  handleDeleteUserGroup = (index, username, oid) => {
    this.setState({ "idGroupDelete": oid })
    this.setState({ "usernameGroupDelete": username })
    this.setState({ "indexGroupDelete": index })
    this.setState({ "openGroupDelete": true })
  }
  handleCloseDialogDeleteUserGroup = () => {
    this.setState({ "openGroupDelete": false })
    this.setState({ "indexGroupDelete": -1 })
    this.setState({ "idGroupDelete": "" })
    this.setState({ "usernameGroupDelete": "" })
  }
  handleSubmitDeleteGroup = (id) => {
    AdminDataService.deleteUserGroup({ "id": id }).then(response => {
      if (response.data.code === 1) {
        this.handleOpenGroups(this.state.indexGroupDelete, this.state.usernameGroupDelete);
        this.setState({ "idGroupDelete": null })
        this.setState({ "idGroupDelete": null })
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
      this.handleCloseDialogDeleteUserGroup();
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    });
  }
  handleDateChangeInitGroup = (date) => {
    try {
      let statusCopy = Object.assign({}, this.state.onEditGroupObject);
      statusCopy.init = date.getTime();
      this.setState({ "onEditGroupObject": statusCopy });
    } catch (error) {
      console.log(error)
    }
  };
  handleDateChangeEndGroup = (date) => {
    try {
      let statusCopy = Object.assign({}, this.state.onEditGroupObject);
      statusCopy.end = date.getTime();
      this.setState({ "onEditGroupObject": statusCopy });
    } catch (error) {
      console.log(error)
    }
  };


  handleSubmitEditGroup = (event) => {
    if (this.state.onEditGroupObject.init > this.state.onEditGroupObject.end) {
      this.setState({ "show": true });
      this.setState({ "msg": "La fecha de inicio no puede ser mayor a la fecha fin" });
      this.setState({ "status": "error" });
      event.preventDefault();
      return;
    }
    this.setState({ "loading": true });
    var data = JSON.stringify(this.state.onEditGroupObject);
    AdminDataService.updateUserGroup(data).then(response => {
      this.setState({ "loading": false });
      this.setState({ "show": true });
      this.setState({ "msg": response.data.message });
      this.setState({ "status": response.data.code === 1 ? "success" : "error" });
      if (response.data.code === 1) {
        this.handleOpenGroups(this.state.onEditGroupIndex, this.state.onEditGroupObject.username);
        this.handleCloseDialogEditGroup();
      }
    }).catch(e => {
      this.setState({ "loading": false });
      console.log(e)
      try {
        if (e.response.status === 400) {
          console.log(e.response)
          this.setState({ "show": true })
          this.setState({ "msg": JSON.stringify(e.response.data.message) })
          this.setState({ "status": "error" })
        }
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
    event.preventDefault();
  }

  handleSubmitReset = (index, username) => {
    this.handleCloseDialog();
    var data = JSON.stringify({ "username": username, "reset2fa": this.state.reset2fa });
    this.setState({ "reset2fa": false })
    AdminDataService.resetUser(data).then(response => {
      if (response.data.code === 1) {
        // data = JSON.stringify({ "search": username, "unique": true });
        try {
          this.handleSubmit(undefined);
        } catch (error) {
          console.log(error)
        }
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  handleSubmitRefreshUID = (index, username) => {
    this.handleCloseDialogUID();
    var data = JSON.stringify({ "username": username });
    AdminDataService.refreshUIDInstitucionalAccount(data).then(response => {
      if (response.data.code === 1) {
        try {
          this.handleSubmit(undefined);
        } catch (error) {
          console.log(error)
        }
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  handleOpenDialog = (index) => {
    this.setState({ "open": true })
    this.setState({ "indexDialog": index })
  }
  handleOpenSearchDialog = () => {
    this.setState({ "searchOpen": true })
  }
  handleCloseSearchDialog = () => {
    this.setState({ "searchOpen": false })
  }

  handleCloseDialog = () => {
    this.setState({ "open": false })
    this.setState({ "indexDialog": -1 })
  }

  handleOpenDialogUID = (index) => {
    this.setState({ "openUID": true })
    this.setState({ "indexDialog": index })
  }
  handleCloseDialogUID = () => {
    this.setState({ "openUID": false })
    this.setState({ "indexDialog": -1 })
  }

  handleOpenDialogSignOut = (index) => {
    this.setState({ "openSignOut": true })
    this.setState({ "indexDialog": index })
  }
  handleCloseDialogSignOut = () => {
    this.setState({ "openSignOut": false })
    this.setState({ "indexDialog": -1 })
  }
  handleSubmitSignOut = (username) => {
    this.handleCloseDialogSignOut();
    var data = JSON.stringify({ "username": username });
    AdminDataService.signOutGoogle(data).then(response => {
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  handleDateChange = (date) => {
    try {
      var formatDate = format(date, "dd/MM/yyyy")
      let statusCopy = Object.assign({}, this.state);
      statusCopy.accountForm["date"] = formatDate
      this.setState({ "accountForm": statusCopy.accountForm });
    } catch (error) {
    }
    this.setState({ "date": date });
  };
  handleDateChangeExpires = (date) => {
    try {
      let statusCopy = Object.assign({}, this.state);
      statusCopy.accountForm["accountexpire"] = date.getTime();
      this.setState({ "accountForm": statusCopy.accountForm });
    } catch (error) {
    }
    this.setState({ "dateDisable": date });
  };
  handleChangeChips = (chips) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.accountForm["responsable"] = chips
    this.setState({ "accountForm": statusCopy.accountForm });
  }
  handleOpenDialogCreate = () => {
    if (this.state.edit === true) {
      this.setDefaultAccountForm()
    }
    this.setState({ "edit": false });
    this.setState({ "openCreate": true })
  }
  handleCloseDialogCreateEdit = () => {
    this.setState({ "openCreate": false })
  }
  handleOpenDialogEdit = (username) => {
    AdminDataService.getAccount({ "username": username }).then(response => {
      if (response.data.code === 1) {
        this.setState({ "idDelete": null })
        const userObject = {
          "username": response.data.data["username"],
          "uid": response.data.data["uid"],
          "familyname": response.data.data["familyname"],
          "snPaternal": response.data.data["snPaternal"],
          "snMaternal": response.data.data["snMaternal"],
          "givenname": response.data.data["givenname"],
          "responsable": response.data.data["responsable"],
          "accountexpire": new Date(new Date(response.data.data["accountexpire"]).getTime() + new Date().getTimezoneOffset() * 60000).getTime(),
          "type": response.data.data["type"],
          "member": response.data.data["member"],
          "observations": response.data.data["observations"],
        }
        this.setState({ "accountForm": userObject });
        if (response.data.data["accountexpire"] !== null) {
          const expire = new Date(new Date(response.data.data["accountexpire"]).getTime() + new Date().getTimezoneOffset() * 60000)
          this.setState({ "dateDisable": expire })
        } else {
          this.setState({ "dateDisable": null })
        }
        if (response.data.data["date"] !== "") {
          const birthday = new Date(new Date(response.data.data["date"]).getTime() + new Date().getTimezoneOffset() * 60000)
          userObject["date"] = format(birthday, "dd/MM/yyyy")
          this.setState({ "date": birthday })
        } else {
          this.setState({ "date": null })
        }
        this.setState({ "openCreate": true });
        this.setState({ "edit": true });
      } else {
        this.setState({ "show": true })
        this.setState({ "msg": response.data.message })
        this.setState({ "status": response.data.code === 1 ? "success" : "error" })
      }
      const exist = response.data.data["type"].includes("institucional");
      this.setState({ "isInstitucional": exist })
    }).catch(e => {
      console.log(e);
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    });
  }
  handleSubmitCreate = (event) => {
    if (this.state.accountForm.familyname === "") {
      this.setState({ "show": true });
      this.setState({ "msg": "Ingrese alguno de los dos apellidos Paterno o Materno" });
      this.setState({ "status": "error" });
      event.preventDefault();
      return;
    }
    this.setState({ "loading": true });
    var data = JSON.stringify(this.state.accountForm);
    AdminDataService.insertAccount(data).then(response => {
      this.setState({ "loading": false });
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
      if (response.data.code === 1) {
        this.searchInsertedOrEdited(this.state.accountForm.username);
        this.handleCloseDialogCreateEdit();
        this.setDefaultAccountForm();
        this.setState({ "edit": false });
      }
    }).catch(e => {
      this.setState({ "loading": false });
      console.log(e)
      try {
        if (e.response.status === 400) {
          this.setState({ "show": true })
          this.setState({ "msg": JSON.stringify(e.response.message) })
          this.setState({ "status": "error" })
        }
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
    event.preventDefault();
  }
  handleSubmitEdit = (event) => {
    if (this.state.accountForm.familyname === "") {
      this.setState({ "show": true });
      this.setState({ "msg": "Ingrese alguno de los dos apellidos Paterno o Materno" });
      this.setState({ "status": "error" });
      event.preventDefault();
      return;
    }
    this.setState({ "loading": true });
    var data = JSON.stringify(this.state.accountForm);
    AdminDataService.updateAccount(data).then(response => {
      this.setState({ "loading": false });
      this.setState({ "show": true });
      this.setState({ "msg": response.data.message });
      this.setState({ "status": response.data.code === 1 ? "success" : "error" });
      if (response.data.code === 1) {
        this.searchInsertedOrEdited(this.state.accountForm.username);
        this.handleCloseDialogCreateEdit();
        this.setDefaultAccountForm();
        this.setState({ "edit": false });
      }
    }).catch(e => {
      this.setState({ "loading": false });
      console.log(e)
      try {
        if (e.response.status === 400) {
          console.log(e.response)
          this.setState({ "show": true })
          this.setState({ "msg": JSON.stringify(e.response.data.message) })
          this.setState({ "status": "error" })
        }
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
    event.preventDefault();
  }
 
  handleOpenObservations = (index, username) => {
    AdminDataService.listUserObservations({ "username": username }).then(response => {
      if (response.data.code === 1) {
        let observations = Object.assign({}, this.state.observations);
        observations[index] = {
          "exist": response.data.data.length !== 0,
          "data": response.data.data
        }
        this.setState({ "observations": observations});
        this.setState({ "msg": response.data.message  });
      }
    })
      .catch(e => {
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
          }
        } catch (error) {
          console.log(error)
        }
      })
  }

  render() {
    const { classes } = this.props;
    return (
      <Card elevation={3} >
        <CardContent >
          <form onSubmit={this.handleSubmit}>
            <TextField onChange={this.handleChange} name="search" fullWidth id="search" label="Usuario" variant="outlined" required
              value={this.state.form.search}
              helperText="Ingrese usuario, ci, nombres o apellidos"
              InputProps={{
                startAdornment: (
                  <IconButton type="submit" className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton type="button" className={classes.iconButton} onClick={this.handleOpenSearchDialog} aria-label="filters">
                    <ArrowDropDownIcon />
                  </IconButton>
                )
              }}
            />{this.state.loading && <CircularProgress size={24} />}
            <Dialog
              open={this.state.searchOpen}
              onClose={this.handleCloseSearchDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="lg">
              <DialogTitle id="alert-dialog-title">{"Filtros de busqueda"}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="sAMAccountName" fullWidth id="fsAMAccountName" label="Usuario" variant="outlined"
                      value={this.state.filter.sAMAccountName} helperText="Cuenta Institucional" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="ci" fullWidth id="fci" label="CI" variant="outlined"
                      value={this.state.filter.ci} helperText="Carnet de Identidad" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="givenName" fullWidth id="fgivenName" label="Nombres" variant="outlined"
                      value={this.state.filter.givenName} helperText="Nombres" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="sn" fullWidth id="fsn" label="Apellidos" variant="outlined"
                      value={this.state.filter.sn} helperText="Cualquiera de los apellidos" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="snPaternal" fullWidth id="fsnPaternal" label="Paterno" variant="outlined"
                      value={this.state.filter.snPaternal} helperText="Apellido Paterno" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="snMaternal" fullWidth id="fsnMaternal" label="Materno" variant="outlined"
                      value={this.state.filter.snMaternal} helperText="Apellido Materno" />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.form.unique}
                        onChange={this.handleChangeSwitchFilterContain}
                        name="filterContain"
                      />
                    }
                    label="Contiene exactamente" />
                </FormGroup>
                <Button color="secondary" autoFocus onClick={this.handleSubmitFilters}>
                  Buscar
                  </Button>
              </DialogActions>
            </Dialog>
          </form>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.fulldetails}
                  onChange={this.handleChangeSwitch}
                  name="changePassword"
                />
              }
              label="Ver Más detalles" />
          </FormGroup>


          {this.state.data.map((user, index) => (
            <Accordion key={user.dn.toString()}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography >{user.attributes.sAMAccountName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="body2" gutterBottom display="block" align="justify">{user.dn}</Typography>
                      {
                        Object.keys(user.attributes).map((key) => {
                          switch (key) {
                            case "cn":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "uid":
                              if (this.state.canEditInstitucionalAccount) {
                                return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b>
                                  {user.attributes["memberOf"] !== null && user.attributes["memberOf"] !== undefined ?
                                    JSON.stringify(user.attributes["memberOf"]).toLowerCase().includes("institucional") ?
                                      <IconButton color="primary" aria-label="reload" onClick={e => this.handleOpenDialogUID(index)}>
                                        <ReplayIcon />
                                      </IconButton > : null : null}
                                </Typography>
                              } else {
                                return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                              }
                            case "accountExpires":
                              var dateExpire = new Date(user.attributes[key][0])
                              var actualDate = new Date()
                              var expired = dateExpire.getTime() < actualDate.getTime()
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]} {expired ? "- EXPIRADO" : "- VIGENTE"}</b></Typography>
                            case "correo":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "givenName":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "sn":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "snMaternal":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "snPaternal":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "mobile":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "streetAddress":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "fechaNacimiento":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "responsableCuenta":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom className={classes.wrapText}><b>{key}: {JSON.stringify(user.attributes[key])}</b></Typography>
                            case "memberOf":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom className={classes.wrapText}><b>{key}: {JSON.stringify(user.attributes[key])}</b></Typography>
                            case "userAccountControl":
                              var activo = user.attributes[key][0] === 544;
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom> <b>{key}: {user.attributes[key]} {activo ? "- ACTIVO" : "- INACTIVO"}</b> </Typography>
                         
                            default:
                              if (this.state.fulldetails) {
                                return (
                                  <Typography key={key} noWrap={true} align="justify" variant="caption" display="block" gutterBottom>{key}: {user.attributes[key]}</Typography>
                                )
                              } else {
                                return null
                              }
                          }
                        })
                      }

                    </Box>
                  </Grid>
                  {index in this.state.gsuit ?
                    <Grid item xs={12} style={this.state.gsuit[index].exist ? { backgroundColor: green[400] } : { backgroundColor: red[300] }}>
                      {
                        this.state.gsuit[index].exist ?
                          <Box>
                            {
                              Object.keys(this.state.gsuit[index].data).map((key) => {
                                switch (key) {
                                  case "primaryEmail":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "creationTime":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "agreedToTerms":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "lastLoginTime":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "suspended":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "orgUnitPath":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "recoveryEmail":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "name":
                                    return (
                                      <Typography key={key} noWrap={true} align="justify" variant="caption" display="block" gutterBottom>
                                        {key}: {typeof this.state.gsuit[index].data[key] === "object" ? JSON.stringify(this.state.gsuit[index].data[key]) : this.state.gsuit[index].data[key].toString()}
                                      </Typography>
                                    )
                                  default:
                                    if (this.state.fulldetails) {
                                      return (
                                        <Typography key={key} noWrap={true} align="justify" variant="caption" display="block" gutterBottom>
                                          {key}: {typeof this.state.gsuit[index].data[key] === "object" ? JSON.stringify(this.state.gsuit[index].data[key]) : this.state.gsuit[index].data[key].toString()}
                                        </Typography>
                                      )
                                    } else {
                                      return null;
                                    }
                                }
                              })
                            }
                            {this.state.canSignOutGsuit ? <Button size="small" color="secondary" onClick={e => this.handleOpenDialogSignOut(index)}>Cerrar Sesiones Abiertas</Button> : null}
                          </Box> : "No existe la cuenta en el Directorio de Google Suit"
                      }

                    </Grid> : null}
                  {index in this.state.groups ?
                    <Grid item xs={12} >
                      {
                        this.state.groups[index].exist ?
                          <Box>
                            <List dense={true}>
                              {this.state.groups[index].data.map((value, i) => {
                                return (
                                  <ListItem key={i} style={value.current ? (new Date().getTime() < value.end.$date ? { backgroundColor: green[300] } : { backgroundColor: yellow[200] }) : { backgroundColor: red[200] }}>
                                    <ListItemText
                                      primary={`${(value.current ? (new Date().getTime() < value.end.$date ? "Vigente" : "Expirado") : "No Vigente")} - ${value.group}`}
                                      secondary={new Date(value.init.$date) + " - " + new Date(value.end.$date)}
                                    />
                                    <ListItemSecondaryAction>
                                      <ButtonGroup variant="text" aria-label="Actions" >
                                        <Button onClick={e => this.handleOpenDialogGroupsEdit(index, value.username, value._id.$oid)} aria-label="edit" ><EditIcon /></Button>
                                        <Button onClick={e => this.handleDeleteUserGroup(index, value.username, value._id.$oid)} aria-label="delete"><DeleteIcon /></Button>
                                      </ButtonGroup>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              })}
                            </List>
                          </Box> : "No se encontraron Grupos Asignados en la Base de Datos"
                      }
                    </Grid> : null}
                    {index in this.state.responsables ?
                    <Grid item xs={12} >
                      {
                        this.state.responsables[index].exist ?
                          <Box>
                            <List dense={true}>
                              {this.state.responsables[index].data.map((value, i) => {
                                return (<ListItem key={i} style={value.current ? { backgroundColor: green[300] } : { backgroundColor: red[300] }}>
                                  <ListItemText
                                      primary={`${JSON.stringify(value.responsable)}`}
                                      secondary={new Date(value.init.$date).toString()}
                                    />
                                </ListItem>)
                              })}
                            </List>
                          </Box> : "No se encontraron Responsables Asignados en la Base de Datos"
                      }
                    </Grid> : null}
                    {index in this.state.observations ?
                    <Grid item xs={12} style={ { backgroundColor: '#f99a5a' }}>
                      {
                        this.state.observations[index].exist ?
                          <Box>
                            <List dense={true}>
                              <Typography   align="justify" variant="caption" display="block" gutterBottom>
                                {`${this.state.observations[index].data}`}                              
                              </Typography>
                            </List>                            
                          </Box> : "No se encontraron Observaciones Asignados en la Base de Datos"
                      }
                    </Grid> : null}
                    
                </Grid>
              </AccordionDetails>
              <Divider />
              <AccordionActions>
              {this.state.canCrudAccounts && user.attributes["memberOf"] !== null && user.attributes["memberOf"] !== undefined ?
                                    JSON.stringify(user.attributes["memberOf"]).toLowerCase().includes("institucional") ?
                                    <Button size="small" color="primary" onClick={e => this.handleOpenResponsable(index, user.attributes.sAMAccountName[0])}>Ver Responsables</Button> : null : null}
                <Button size="small" color="primary" onClick={e => this.handleOpenObservations(index, user.attributes.sAMAccountName[0])}>Ver Observaciones</Button>
                {this.state.canCrudAccounts ? <Button size="small" color="secondary" onClick={e => this.handleOpenGroups(index, user.attributes.sAMAccountName[0])}>Ver Grupos</Button> : null}
                {this.state.canCrudAccounts ? <Button size="small" color="primary" onClick={e => this.handleOpenDialogEdit(user.attributes.sAMAccountName[0])}>Editar</Button> : null}
                {this.state.canGsuit ? <Button size="small" color="secondary" onClick={e => this.handleSubmitGsuit(index, user.attributes.sAMAccountName[0])}>Ver Gsuite</Button> : null}
                {/* {this.state.canReset ? <Button size="small" color="primary" onClick={e => this.handleSubmitReset(index, user.attributes.sAMAccountName[0])}>Reset Cuenta</Button>: null} */}
                {this.state.canReset ? <Button size="small" color="primary" onClick={e => this.handleOpenDialog(index)}>Reset Cuenta</Button> : null}
              </AccordionActions>
              <Dialog
                open={this.state.open && this.state.indexDialog === index}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Reset Cuenta de " + user.attributes.sAMAccountName[0]}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {"Desea hacer un reset a la Cuenta, el usuario tendrá que volver a activar la cuenta institucional?"}
                  </DialogContentText>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.reset2fa}
                          onChange={this.handleChangeSwitch2FAReset}
                          name="Reset2FA"
                        />
                      }
                      label="Eliminar tambien 2do. Factor de Autenticación" />
                  </FormGroup>

                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialog} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={e => this.handleSubmitReset(index, user.attributes.sAMAccountName[0])} color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.openUID && this.state.indexDialog === index}
                onClose={this.handleCloseDialogUID}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Cambiar el Código UID de: " + user.attributes.sAMAccountName[0]}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {"Desea actualizar el ccódigo UID de la Cuenta Institucional?"}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialogUID} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={e => this.handleSubmitRefreshUID(index, user.attributes.sAMAccountName[0])} color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.openSignOut && this.state.indexDialog === index}
                onClose={this.handleCloseDialogSignOut}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Cerrar las sesiones de todos los dispositivos de: " + user.attributes.sAMAccountName[0]}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {"Se va cerrar la sesión de todos los dispositivos asociados y navegadores"}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialogSignOut} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={e => this.handleSubmitSignOut(user.attributes.sAMAccountName[0])} color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
            </Accordion>

          ))}

        </CardContent>

        {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}
        {this.state.canCrudAccounts ?
          <Dialog
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            open={this.state.onEditGroupDialog}
            onClose={this.handleCloseDialogEditGroup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <form onSubmit={this.handleSubmitEditGroup}>


              <DialogTitle id="alert-dialog-title">{`Editar Grupo de ${this.state.onEditGroupObject.username}`}</DialogTitle>
              <DialogContent>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField onChange={this.handleChangeGroup} name="group" fullWidth id="group" label="Grupo" variant="outlined" required value={this.state.onEditGroupObject.group} />
                  </Grid>

                  <Grid item xs={12} md={12} lg={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
                      <KeyboardDateTimePicker
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        id="date-picker-init"
                        label="Inicio"
                        minDateMessage="Fecha de inicio no válida"
                        maxDateMessage="Fecha de inicio no válida"
                        maxDate={this.state.onEditGroupObject.end}
                        value={this.state.onEditGroupObject.init}
                        onChange={this.handleDateChangeInitGroup}
                        invalidDateMessage="Ingrese una fecha válida"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }} required
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} md={12} lg={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
                      <KeyboardDateTimePicker
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        id="date-picker-init"
                        label="Inicio"
                        minDateMessage="Fecha de inicio no válida"
                        maxDateMessage="Fecha de inicio no válida"
                        minDate={this.state.onEditGroupObject.init}
                        value={this.state.onEditGroupObject.end}
                        onChange={this.handleDateChangeEndGroup}
                        invalidDateMessage="Ingrese una fecha válida"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }} required
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} md={12} lg={4}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.onEditGroupObject.current}
                            onChange={this.handleChangeCurrentGroup}
                            name="current"
                          />
                        }
                        label="Vigencia" />
                    </FormGroup>
                  </Grid>

                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDialogEditGroup} color="primary" disabled={this.state.loading}>Cancelar</Button>
                <Button color="secondary" type="submit" autoFocus disabled={this.state.loading}>{this.state.loading ? <CircularProgress color="secondary" size={24} /> : "Aceptar"}</Button>
              </DialogActions>
            </form>
          </Dialog> : null}

        {this.state.canCrudAccounts ?
          <Dialog
            open={this.state.openGroupDelete}
            onClose={this.handleCloseDialogDeleteUserGroup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Eliminar la asignación de grupo?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Aceptar para confirmar la eliminación de la asignación"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={e => this.handleSubmitDeleteGroup(this.state.idGroupDelete)} color="primary" >
                Aceptar
          </Button>
              <Button onClick={this.handleCloseDialogDeleteUserGroup} color="secondary" autoFocus>
                Cancelar
          </Button>
            </DialogActions>
          </Dialog> : null}

        {this.state.canCrudAccounts ?
          <div>
            <Fab aria-label="Add" className={classes.fab} color='primary' onClick={this.handleOpenDialogCreate}>
              <AddIcon />
            </Fab>
            <Dialog
              fullWidth
              maxWidth="lg"
              disableBackdropClick
              disableEscapeKeyDown
              open={this.state.openCreate}
              onClose={this.handleCloseDialogCreateEdit}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <form onSubmit={this.state.edit ? this.handleSubmitEdit : this.handleSubmitCreate}>


                <DialogTitle id="alert-dialog-title">{this.state.edit ? "Editar Cuenta " : "Crear Cuenta"}</DialogTitle>
                <DialogContent>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField onChange={this.handleChangeCrud} name="username" fullWidth id="username" label="Usuario" variant="outlined" required disabled={this.state.edit}
                        value={"username" in this.state.accountForm ? this.state.accountForm["username"] : ""}
                        InputProps={{ inputComponent: UsernameMask }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField onChange={this.handleChangeCrud} name="givenname" fullWidth id="givenname" label="Nombres" variant="outlined" required
                        value={"givenname" in this.state.accountForm ? this.state.accountForm["givenname"] : ""} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <TextField onChange={this.handleChangeCrud} name="snPaternal" fullWidth id="snPaternal" label="Paterno" variant="outlined"
                        value={"snPaternal" in this.state.accountForm ? this.state.accountForm["snPaternal"] : ""} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <TextField onChange={this.handleChangeCrud} name="snMaternal" fullWidth id="snMaternal" label="Materno" variant="outlined"
                        value={"snMaternal" in this.state.accountForm ? this.state.accountForm["snMaternal"] : ""} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField name="familyname" fullWidth id="familyname" label="Apellidos" variant="outlined" required
                        value={"familyname" in this.state.accountForm ? this.state.accountForm["familyname"] : ""} InputProps={{
                          readOnly: true,
                        }} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField onChange={this.handleChangeCrud} name="uid" fullWidth id="uid" label="CI o Código de Activación" variant="outlined" required
                        value={"uid" in this.state.accountForm ? this.state.accountForm["uid"] : ""} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
                        <KeyboardDatePicker required
                          fullWidth
                          disableFuture
                          autoOk
                          openTo="year"
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha de nacimiento"
                          views={["year", "month", "date"]}
                          minDateMessage="Fecha de nacimiento no válida"
                          minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
                          maxDateMessage="Fecha de nacimiento no válida"
                          maxDate={new Date().setFullYear(new Date().getFullYear() - 15)}
                          value={this.state.date}
                          onChange={this.handleDateChange}
                          invalidDateMessage="Ingrese una fecha válida"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
                        <KeyboardDateTimePicker
                          fullWidth
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy HH:mm"
                          margin="normal"
                          name="accountexpire"
                          id="date-picker-expire"
                          label="Fecha Expiración de Cuenta (UTC)"
                          value={this.state.dateDisable}
                          onChange={this.handleDateChangeExpires}
                          invalidDateMessage="Ingrese una fecha válida"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-mutiple-chip-label">Estamento</InputLabel>
                        <Select
                          required
                          name="type"
                          labelId="demo-mutiple-chip-label"
                          id="type"
                          multiple
                          value={this.state.accountForm.type}
                          onChange={this.handleChangeCrud}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {
                                // @ts-ignore
                                selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                            </div>
                          )}>
                          {AdminDataService.listEstamentoArray().map((name) => (
                            <MenuItem key={name} value={name} >{name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {this.state.isInstitucional ?
                      <Grid item xs={12} md={12} lg={6}>
                        <ChipInput
                          label="Responsables"
                          fullWidth
                          variant="outlined"
                          defaultValue={this.state.accountForm.responsable}
                          onChange={(chips) => this.handleChangeChips(chips)}
                        />
                      </Grid> : null}
                    <Grid item xs={12} md={12} lg={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-mutiple-chip-label">Grupos</InputLabel>
                        <Select
                          name="member"
                          labelId="demo-mutiple-chip-label"
                          id="member"
                          multiple
                          value={this.state.accountForm.member}
                          onChange={this.handleChangeCrud}
                          input={<Input id="select-multiple-chip-group" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {
                                // @ts-ignore
                                selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                            </div>
                          )}>
                          {
                            this.state.dataGroups.map((member) => (
                              <MenuItem key={member.group_ad} value={member.group_ad} >{member.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>                      
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField onChange={this.handleChangeCrud} name="observations" fullWidth id="observations" label="Observaciones" variant="outlined"
                          value={"observations" in this.state.accountForm ? this.state.accountForm["observations"] : ""} />                        
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialogCreateEdit} color="primary" disabled={this.state.loading}>Cancelar</Button>
                  <Button color="secondary" type="submit" autoFocus disabled={this.state.loading}>{this.state.loading ? <CircularProgress color="secondary" size={24} /> : "Aceptar"}</Button>
                </DialogActions>
              </form>
            </Dialog>
          </div>
          : null}
      </Card>
    );
  }
}
// @ts-ignore
export default withStyles(useStyles)(AdminUsers);