import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,  TextField } from '@material-ui/core';
import MaterialTable from 'material-table';

import CircularProgress from '@material-ui/core/CircularProgress';
import tableIcons from "../utils/TableIcons"
import AdminDataService from "../../services/admin.service";
import SearchIcon from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import Snackbar from "components/Snackbars/Snackbar";
const useStyles = theme => ({

});
class Admin2FA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {"rows": [], "show": false, "msg": "", "status": "success", "openDelete": false, "idDelete": "", "form": { "search": "" } , "loading": false}
  }
  handleClearSnack = (event) => {
    this.setState({ "show": false })
  }
  handleDelete = (id)=> {
    this.setState({"idDelete": id})
    this.setState({"openDelete": true})
  }
  handleSubmit = (event) => {
    this.setState({ "loading": true });
    var data = JSON.stringify(this.state.form);
    AdminDataService.list2FA(data).then(response => {
      if (response.data.code === 1) {
        this.setState({ "loading": false });
        const rows = []
        console.log(response.data.data)
        for (const item of response.data.data){
          item["id"] = item._id.$oid;
          item["date"] = new Date(item.date);
          rows.push(item);
        }
        this.setState({"rows": rows})
      }
    }).catch(e => {
      console.log(e)
      this.setState({ "loading": false });
    });
    event.preventDefault();
  }
  handleChange = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
  }
  handleCloseDialogDelete = () => {
    this.setState({"openDelete": false})
  }
  handleSubmitDelete = (id) => {
    this.handleCloseDialogDelete();
    AdminDataService.delete2FA(id).then(response => {
      if (response.data.code === 1) {
        this.setState({"idDelete": null})
        this.handleSubmit(null);
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
        console.log(e);
      });
  }
  render(){
    return (
      <Card elevation={3}>
        <CardContent>
          <form onSubmit={this.handleSubmit}>
            <TextField onChange={this.handleChange} name="search" fullWidth id="search" label="Usuario o CI" variant="outlined" required
              helperText="Ingrese Carnet de Identidad o Usuario"
              InputProps={{
                endAdornment: (
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                )
              }}
            />{this.state.loading && <CircularProgress size={24} />}
          </form>
          <div style={{ width: '100%' }}>
            <MaterialTable
              icons={tableIcons}
              columns={[
                { title: 'ID', field: 'id', hidden: true },
                { title: 'Usuario', field: 'username' },
                { title: 'Verificado', field: 'verified' , type: "boolean"},
                { title: 'Fecha', field: 'date', type: "datetime", filtering: false }, // type: 'numeric'},
              ]}
              data={this.state.rows}
              title="2FA"
              options={{
                filtering: true
              }}
              actions={[
                {
                  icon:() => <Delete />,
                  tooltip: 'Borrar',
                  onClick: (event, rowData) => this.handleDelete(rowData.id)
                }
              ]}
            />
          </div>
        </CardContent>

        <Dialog
              open={this.state.openDelete && this.state.idDelete !== null}
              onClose={this.handleCloseDialogDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"Eliminar el Permiso?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Aceptar para confirmar la eliminación de la verificación de 2 pasos"}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e => this.handleSubmitDelete(this.state.idDelete)} color="primary" >
                  Aceptar
                </Button>
                <Button onClick={this.handleCloseDialogDelete} color="secondary" autoFocus>
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>

        {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}
      </Card>
    )
  }
}
export default withStyles(useStyles)(Admin2FA);