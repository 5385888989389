import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
class Captcha extends React.Component {
  constructor(props){
    super (props)
    this.refCaptcha = React.createRef();
  }
  resetCaptcha = () => {
    this.refCaptcha.current.reset();
  }
  render(){
    return (
      <ReCAPTCHA
              ref={this.refCaptcha}
              sitekey={"6Len8tkZAAAAAAd18XLmrkBukXs2yDL7HuWOML8F"}
              onChange={this.props.onChangeRecaptcha}
              theme={ this.props.darkState ? "dark": "light"}
            />
    )
  }
}
export default Captcha;