import React from 'react';
import Typography from '@material-ui/core/Typography';
import VerificationRuForm from "views/Verification/VerificationRuForm";

function VerificationRuPage(){

  return (
    <div>
      <Typography variant="h2"   gutterBottom>
        Bienvenido
      </Typography>
      <Typography variant="h6" gutterBottom>
        Necesitamos más información acerca de usted
      </Typography>
      <VerificationRuForm/>
    </div>
  );
}
export default VerificationRuPage;