import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from "@material-ui/core/TextField";

import Divider from '@material-ui/core/Divider';
import AdminDataService from "../../services/admin.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "components/Snackbars/Snackbar";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionActions, Box, Button, ButtonGroup, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, Input, InputLabel, List, ListItem, ListItemText, MenuItem, Select } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { green, red, yellow } from '@material-ui/core/colors';
import ReplayIcon from '@material-ui/icons/Replay';
import AddIcon from '@material-ui/icons/Add';
import UsernameMask from "components/Masks/UsernameMask"
import { es } from "date-fns/locale";
import format from 'date-fns/format'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import ChipInput from 'material-ui-chip-input'
const useStyles = theme => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  wrapText: {
    wordWrap: "break-word"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
});

class AdminUsersEstudents extends React.Component {
  constructor(props) {
    super(props);

    const actual = new Date()
    actual.setFullYear(actual.getFullYear() - 18)
    this.state = {
      "form": { "search": "", "unique": false }, "loading": false, "show": false, "msg": "", "status": "success",
      "open": false, "indexDialog": -1, "data": [], "fulldetails": false, "gsuit": {},
      "canGsuit": props.permissions.includes("list-google"),
      "canResetEstudent": props.permissions.includes("reset-ad-est"),
      "canEditInstitucionalAccount": props.permissions.includes("institucional-account-edit"),
      "canSignOutGsuit": props.permissions.includes("signout-google"),
      "canCrudAccounts": props.permissions.includes("crud-ad"),
      "openUID": false,
      "openSignOut": false,
      "openCreate": false,
      "accountForm": this.defaultValueAccountForm(),
      "date": actual,
      "dateDisable": actual,
      "isInstitucional": false,
      "dataGroups": [],
      "edit": false,
      "groups": [],
      "onEditGroupDialog": false,
      "responsables": [],
      "onEditGroupIndex": -1,
      "onEditGroupObject": {
        "id": "", "username": "", "init": 0, "end": 0, "current": true, "group": ""
      },
      "idGroupDelete": "",
      "openGroupDelete": false,
      "usernameGroupDelete": "",
      "reset2fa": false,
      "searchOpen": false,
      "filter": {
        "sAMAccountName": "",
        "givenName": "",
        "ci": "",
        "snPaternal": "",
        "snMaternal": "",
        "sn": ""
      },
      "observations": []
    }
  }
  defaultValueAccountForm = () => {
    const actual = new Date()
    actual.setFullYear(actual.getFullYear() - 18)
    return {
      "username": "",
      "familyname": "",
      "snPaternal": "",
      "snMaternal": "",
      "date": format(actual, "dd/MM/yyyy"),
      "type": [AdminDataService.listEstamentoArray()[0]],
      "accountexpire": actual.getTime(),
      "member": [],
      "responsable": [],
      "observations": ""    
    }
  }
  setDefaultAccountForm = () => {
    const actual = new Date()
    actual.setFullYear(actual.getFullYear() - 18)
    this.setState({ "accountForm": this.defaultValueAccountForm() });
    this.setState({ "date": actual })
    this.setState({ "dateDisable": actual })
    this.setState({ "isInstitucional": false })
  }
 
  handleClearSnack = (event) => {
    this.setState({ "show": false })
  }

  handleChange = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
  }

  handleChangeFilters = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.filter[event.target.name] = event.target.value
    this.setState({ "filter": statusCopy.filter });
  }
  handleChangeSwitchFilterContain = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form["unique"] = event.target.checked
    this.setState({ "form": statusCopy.form });
  }

  handleSubmitFilters = (event) => {
    let query = "";
    if (this.state.filter.sAMAccountName !== "") {
      query += `sAMAccountName:${this.state.filter.sAMAccountName} `;
    }
    if (this.state.filter.ci !== "") {
      query += `uid:${this.state.filter.ci} `;
    }
    if (this.state.filter.givenName !== "") {
      query += `givenName:${this.state.filter.givenName} `;
    }
    if (this.state.filter.snPaternal !== "") {
      query += `snPaternal:${this.state.filter.snPaternal} `;
    }
    if (this.state.filter.snMaternal !== "") {
      query += `snMaternal:${this.state.filter.snMaternal} `;
    }
    if (this.state.filter.sn !== "") {
      query += `sn:${this.state.filter.sn} `;
    }
    if (query !== "") {
      let statusCopy = Object.assign({}, this.state);
      statusCopy.form["search"] = query
      this.setState({ "filter": statusCopy.filter });
    }
    this.handleCloseSearchDialog()
    this.handleSubmit(event)
  }

  searchInsertedOrEdited = (username) => {
    this.handleChange({ target: { "name": "search", "value": username } })
    try {
      this.handleSubmit(undefined);
    } catch (error) {
    }
  }
  handleChangeCrud = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.accountForm[event.target.name] = event.target.value
    if (event.target.name === "snPaternal") {
      if ("snMaternal" in statusCopy.accountForm) {
        let maternal = statusCopy.accountForm["snMaternal"]
        maternal = maternal.trim()
        let actual = event.target.value.trim()
        statusCopy.accountForm["familyname"] = actual + (maternal.length > 0 ? (actual.length > 0 ? ` ${maternal}` : maternal) : "")
      } else {
        // @ts-ignore
        statusCopy.accountForm["familyname"] = event.target.value.trim()
      }
    }
    if (event.target.name === "snMaternal") {
      if ("snPaternal" in statusCopy.accountForm) {
        let paternal = statusCopy.accountForm["snPaternal"]
        paternal = paternal.trim()
        let actual = event.target.value.trim()
        // @ts-ignore
        statusCopy.accountForm["familyname"] = (paternal.length > 0 ? (actual.length > 0 ? `${paternal} ` : paternal) : "") + event.target.value.trim()
      } else {
        // @ts-ignore
        statusCopy.accountForm["familyname"] = event.target.value.trim()
      }
    }

    if (event.target.name === "type") {
      const exist = event.target.value.includes("institucional");
      this.setState({ "isInstitucional": exist })
      if (!exist) {
        delete statusCopy.accountForm["responsable"]
      }
    }   
    this.setState({ "accountForm": statusCopy.accountForm });
  }

  handleSubmit = (event) => {
    this.setState({ "loading": true })
    var data = JSON.stringify(this.state.form);
    AdminDataService.listUsersStudent(data).then(response => {
      if (response.data.code === 1) {
        this.setState({ "data": response.data.data });
        this.setState({ "gsuit": {} })
      } else {
      }
      this.setState({ "loading": false })
    })
      .catch(e => {
        this.setState({ "loading": false })
        console.log(e);
        try {
          if (e.response.status === 403) {
            AdminDataService.logout();
            this.setState({ "show": true })
            this.setState({ "msg": e.response.data.message })
            this.setState({ "status": "error" })
          }
          if (e.response.status === 401) {
            AdminDataService.logout();
            this.setState({ "show": true })
            this.setState({ "msg": e.response.data.message })
            this.setState({ "status": "error" })
          }
        } catch (error) {
          console.log(error)
        }
      })
    event.preventDefault();
  }
  handleChangeSwitch = (event) => {
    this.setState({ "fulldetails": event.target.checked });
  }
  handleChangeSwitch2FAReset = (event) => {
    this.setState({ "reset2fa": event.target.checked });
  }

  handleSubmitGsuit = (index, username) => {
    var data = JSON.stringify({ "search": username });
    AdminDataService.listGUsers(data).then(response => {
      if (response.data.code === 1) {
        let gsuit = Object.assign({}, this.state.gsuit);
        gsuit[index] = {
          "exist": response.data.data !== null,
          "data": response.data.data
        }

        this.setState({ "gsuit": gsuit });
      }
    })
      .catch(e => {
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
          }
        } catch (error) {
          console.log(error)
        }
      })
  }

  handleSubmitReset = (index, username) => {
    this.handleCloseDialog();
    var data = JSON.stringify({ "username": username, "reset2fa": this.state.reset2fa });
    this.setState({ "reset2fa": false })
    AdminDataService.resetUserStudent(data).then(response => {
      if (response.data.code === 1) {
        // data = JSON.stringify({ "search": username, "unique": true });
        try {
          this.handleSubmit(undefined);
        } catch (error) {
          console.log(error)
        }
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  handleSubmitRefreshUID = (index, username) => {
    this.handleCloseDialogUID();
    var data = JSON.stringify({ "username": username });
    AdminDataService.refreshUIDInstitucionalAccount(data).then(response => {
      if (response.data.code === 1) {
        try {
          this.handleSubmit(undefined);
        } catch (error) {
          console.log(error)
        }
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
  }
  handleOpenDialog = (index) => {
    this.setState({ "open": true })
    this.setState({ "indexDialog": index })
  }
  handleOpenSearchDialog = () => {
    this.setState({ "searchOpen": true })
  }
  handleCloseSearchDialog = () => {
    this.setState({ "searchOpen": false })
  }

  handleCloseDialog = () => {
    this.setState({ "open": false })
    this.setState({ "indexDialog": -1 })
  }

  handleOpenDialogUID = (index) => {
    this.setState({ "openUID": true })
    this.setState({ "indexDialog": index })
  }
  handleCloseDialogUID = () => {
    this.setState({ "openUID": false })
    this.setState({ "indexDialog": -1 })
  }

  handleOpenDialogSignOut = (index) => {
    this.setState({ "openSignOut": true })
    this.setState({ "indexDialog": index })
  }
  handleCloseDialogSignOut = () => {
    this.setState({ "openSignOut": false })
    this.setState({ "indexDialog": -1 })
  }
  handleSubmitSignOut = (username) => {
    this.handleCloseDialogSignOut();
    var data = JSON.stringify({ "username": username });
    AdminDataService.signOutGoogle(data).then(response => {
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <Card elevation={3} >
        <CardContent >
          <form onSubmit={this.handleSubmit}>
            <TextField onChange={this.handleChange} name="search" fullWidth id="search" label="Usuario del Estudiante" variant="outlined" required
              value={this.state.form.search}
              helperText="Ingrese usuario, ci, nombres o apellidos"
              InputProps={{
                startAdornment: (
                  <IconButton type="submit" className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton type="button" className={classes.iconButton} onClick={this.handleOpenSearchDialog} aria-label="filters">
                    <ArrowDropDownIcon />
                  </IconButton>
                )
              }}
            />{this.state.loading && <CircularProgress size={24} />}
            <Dialog
              open={this.state.searchOpen}
              onClose={this.handleCloseSearchDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="lg">
              <DialogTitle id="alert-dialog-title">{"Filtros de busqueda"}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="sAMAccountName" fullWidth id="fsAMAccountName" label="Usuario" variant="outlined"
                      value={this.state.filter.sAMAccountName} helperText="Cuenta Institucional" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="ci" fullWidth id="fci" label="CI" variant="outlined"
                      value={this.state.filter.ci} helperText="Carnet de Identidad" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="givenName" fullWidth id="fgivenName" label="Nombres" variant="outlined"
                      value={this.state.filter.givenName} helperText="Nombres" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="sn" fullWidth id="fsn" label="Apellidos" variant="outlined"
                      value={this.state.filter.sn} helperText="Cualquiera de los apellidos" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="snPaternal" fullWidth id="fsnPaternal" label="Paterno" variant="outlined"
                      value={this.state.filter.snPaternal} helperText="Apellido Paterno" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField onChange={this.handleChangeFilters} name="snMaternal" fullWidth id="fsnMaternal" label="Materno" variant="outlined"
                      value={this.state.filter.snMaternal} helperText="Apellido Materno" />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.form.unique}
                        onChange={this.handleChangeSwitchFilterContain}
                        name="filterContain"
                      />
                    }
                    label="Contiene exactamente" />
                </FormGroup>
                <Button color="secondary" autoFocus onClick={this.handleSubmitFilters}>
                  Buscar
                  </Button>
              </DialogActions>
            </Dialog>
          </form>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.fulldetails}
                  onChange={this.handleChangeSwitch}
                  name="changePassword"
                />
              }
              label="Ver Más detalles" />
          </FormGroup>


          {this.state.data.map((user, index) => (
            <Accordion key={user.dn.toString()}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography >{user.attributes.sAMAccountName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="body2" gutterBottom display="block" align="justify">{user.dn}</Typography>
                      {
                        Object.keys(user.attributes).map((key) => {
                          switch (key) {
                            case "cn":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "uid":
                              if (this.state.canEditInstitucionalAccount) {
                                return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b>
                                  {user.attributes["memberOf"] !== null && user.attributes["memberOf"] !== undefined ?
                                    JSON.stringify(user.attributes["memberOf"]).toLowerCase().includes("institucional") ?
                                      <IconButton color="primary" aria-label="reload" onClick={e => this.handleOpenDialogUID(index)}>
                                        <ReplayIcon />
                                      </IconButton > : null : null}
                                </Typography>
                              } else {
                                return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                              }
                            case "accountExpires":
                              var dateExpire = new Date(user.attributes[key][0])
                              var actualDate = new Date()
                              var expired = dateExpire.getTime() < actualDate.getTime()
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]} {expired ? "- EXPIRADO" : "- VIGENTE"}</b></Typography>
                            case "correo":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "givenName":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "sn":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "snMaternal":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "snPaternal":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "mobile":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "streetAddress":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "fechaNacimiento":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom><b>{key}: {user.attributes[key]}</b></Typography>
                            case "responsableCuenta":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom className={classes.wrapText}><b>{key}: {JSON.stringify(user.attributes[key])}</b></Typography>
                            case "memberOf":
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom className={classes.wrapText}><b>{key}: {JSON.stringify(user.attributes[key])}</b></Typography>
                            case "userAccountControl":
                              var activo = user.attributes[key][0] === 544;
                              return <Typography key={key} align="justify" display="block" variant="body2" gutterBottom> <b>{key}: {user.attributes[key]} {activo ? "- ACTIVO" : "- INACTIVO"}</b> </Typography>
                         
                            default:
                              if (this.state.fulldetails) {
                                return (
                                  <Typography key={key} noWrap={true} align="justify" variant="caption" display="block" gutterBottom>{key}: {user.attributes[key]}</Typography>
                                )
                              } else {
                                return null
                              }
                          }
                        })
                      }

                    </Box>
                  </Grid>
                  {index in this.state.gsuit ?
                    <Grid item xs={12} style={this.state.gsuit[index].exist ? { backgroundColor: green[400] } : { backgroundColor: red[300] }}>
                      {
                        this.state.gsuit[index].exist ?
                          <Box>
                            {
                              Object.keys(this.state.gsuit[index].data).map((key) => {
                                switch (key) {
                                  case "primaryEmail":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "creationTime":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "agreedToTerms":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "lastLoginTime":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "suspended":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "orgUnitPath":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "recoveryEmail":
                                    return <Typography key={key} align="justify" display="block" variant="caption" gutterBottom><b>{key}: {this.state.gsuit[index].data[key].toString()}</b></Typography>
                                  case "name":
                                    return (
                                      <Typography key={key} noWrap={true} align="justify" variant="caption" display="block" gutterBottom>
                                        {key}: {typeof this.state.gsuit[index].data[key] === "object" ? JSON.stringify(this.state.gsuit[index].data[key]) : this.state.gsuit[index].data[key].toString()}
                                      </Typography>
                                    )
                                  default:
                                    if (this.state.fulldetails) {
                                      return (
                                        <Typography key={key} noWrap={true} align="justify" variant="caption" display="block" gutterBottom>
                                          {key}: {typeof this.state.gsuit[index].data[key] === "object" ? JSON.stringify(this.state.gsuit[index].data[key]) : this.state.gsuit[index].data[key].toString()}
                                        </Typography>
                                      )
                                    } else {
                                      return null;
                                    }
                                }
                              })
                            }
                            {this.state.canSignOutGsuit ? <Button size="small" color="secondary" onClick={e => this.handleOpenDialogSignOut(index)}>Cerrar Sesiones Abiertas</Button> : null}
                          </Box> : "No existe la cuenta en el Directorio de Google Suit"
                      }

                    </Grid> : null}   
                </Grid>
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                {this.state.canGsuit ? <Button size="small" color="secondary" onClick={e => this.handleSubmitGsuit(index, user.attributes.sAMAccountName[0])}>Ver Gsuite</Button> : null}
                {/* {this.state.canReset ? <Button size="small" color="primary" onClick={e => this.handleSubmitReset(index, user.attributes.sAMAccountName[0])}>Reset Cuenta</Button>: null} */}
                {this.state.canResetEstudent ? <Button size="small" color="primary" onClick={e => this.handleOpenDialog(index)}>Reset Cuenta</Button> : null}
              </AccordionActions>
              <Dialog
                open={this.state.open && this.state.indexDialog === index}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Reset Cuenta de " + user.attributes.sAMAccountName[0]}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {"Desea hacer un reset a la Cuenta, el usuario tendrá que volver a activar la cuenta institucional?"}
                  </DialogContentText>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.reset2fa}
                          onChange={this.handleChangeSwitch2FAReset}
                          name="Reset2FA"
                        />
                      }
                      label="Eliminar tambien 2do. Factor de Autenticación" />
                  </FormGroup>

                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialog} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={e => this.handleSubmitReset(index, user.attributes.sAMAccountName[0])} color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.openUID && this.state.indexDialog === index}
                onClose={this.handleCloseDialogUID}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Cambiar el Código UID de: " + user.attributes.sAMAccountName[0]}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {"Desea actualizar el ccódigo UID de la Cuenta Institucional?"}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialogUID} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={e => this.handleSubmitRefreshUID(index, user.attributes.sAMAccountName[0])} color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.openSignOut && this.state.indexDialog === index}
                onClose={this.handleCloseDialogSignOut}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Cerrar las sesiones de todos los dispositivos de: " + user.attributes.sAMAccountName[0]}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {"Se va cerrar la sesión de todos los dispositivos asociados y navegadores"}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialogSignOut} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={e => this.handleSubmitSignOut(user.attributes.sAMAccountName[0])} color="secondary" autoFocus>
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
            </Accordion>

          ))}

        </CardContent>

        {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}

      </Card>
    );
  }
}
// @ts-ignore
export default withStyles(useStyles)(AdminUsersEstudents);