import React from 'react';
import Typography from '@material-ui/core/Typography';
import VerificationBirthdayForm from "views/Verification/VerificationBirthdayForm";

function VerificationBirthdayPage(){

  return (
    <div>
      <Typography variant="h2"   gutterBottom>
        Bienvenido
      </Typography>
      <Typography variant="h6" gutterBottom>
        Necesitamos más información acerca de usted
      </Typography>
      <VerificationBirthdayForm/>
    </div>
  );
}
export default VerificationBirthdayPage;