import React from 'react';
import Typography from '@material-ui/core/Typography';
import ActivateAccountForm from "views/Accounts/ActivateAccountForm";
import UsernamePage from "views/Accounts/UsernamePage";
import MigrationStatePage from "views/Google/MigrationStatePage";
class ActivateAccountPage extends React.Component{
  handleUsername =  (username) =>{}
  render(){
  return (
    <div>
      <Typography variant="h2"   gutterBottom>
          <UsernamePage initTitle="Tus datos" endTitle="!" returnUsername={this.handleUsername}/>
      </Typography>
      <MigrationStatePage/>
      <br/>
      <br/>
      <Typography variant="h6" gutterBottom>
        Los campos más importantes se encuentran marcados con "*" 
      </Typography>
      <ActivateAccountForm />
    </div>
  );}
}
export default ActivateAccountPage;