import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types'

function CellPhoneMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /[6-7]/,  /\d/, /\d/, /\d/,  /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
        guide={false}
      />
    );
  }

CellPhoneMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

export default CellPhoneMask;