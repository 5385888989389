import React, { useState } from 'react';
import 'assets/css/App.css';
import ElevateAppBar from "views/ElevateAppBar";
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';




function App(props) {
  const getActualThemeIsDark= () => {
    return localStorage.getItem("theme") === "dark"
  }

  const [darkState, setDarkState] = useState(getActualThemeIsDark());

  const toogleTheme = () => {
    let ltheme = localStorage.getItem("theme")
    if (ltheme === null) {
      ltheme = "light"
    }
    if (ltheme === "light") {
      ltheme = "dark"
    } else {
      ltheme = "light"
    }
    localStorage.setItem('theme', ltheme);
    setDarkState(localStorage.getItem("theme") === "dark")
  }


  const theme = createMuiTheme({
    palette: {
      type: darkState ? 'dark' : 'light',
      primary: {
        main: darkState ? '#6d77b5'  : '#3f51b5', //HSV 231°, 40%, 71% -  //231°, 65%, 71%
        // dark: "#ff0000",
        // light: '#006600',
      },
      secondary:{
        main: darkState ? "#f54985" : "#f50057"  //HSV 339°, 70%, 96% -  //339°, 100%, 96%
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ElevateAppBar toogleTheme={toogleTheme} getActualThemeIsDark={getActualThemeIsDark}  darkState={darkState}/>
      </div>
    </ThemeProvider>
  );
}
export default App;