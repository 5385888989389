import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types'
import emailMask from 'text-mask-addons/dist/emailMask'

function EmailMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={emailMask}
        placeholderChar={'\u2000'}
        showMask
        guide={false}
      />
    );
  }

EmailMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

export default EmailMask;