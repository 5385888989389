import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  img: {
    width: 100,
    height: 100,
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function ComplexGrid(props) {
  const classes = useStyles();
  console.log(props)
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
              {
                props.ItemLink === null ? <img className={classes.img} alt="item" src={props.ItemIcon} /> 
                :
                <Link href={props.ItemLink} target="_blank">
                <img className={classes.img} alt="item" src={props.ItemIcon} />
                </Link>
              }
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  {props.ItemTitle}
                </Typography>
                <Typography variant="body2" gutterBottom>
                {props.ItemContent}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {props.ItemFooter}
                </Typography>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}