import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Card, CardContent, IconButton, TextField, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import AdminDataService from "../../services/admin.service";
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from "components/Snackbars/Snackbar";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
const useStyles = theme => ({

});
class SearchServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = { "show": false, "msg": "", "status": "success", "loading": false, "search": "", "wsEstudiante": null, "wsDocenteP": null, "wsDocenteS": null, "wsAdminP": null, "wsAdminS": null }
  }
  handleClearSnack = (event) => {
    this.setState({ "show": false })
  }
  handleSubmit = (event) => {
    this.setState({ "loading": true });
    var data = JSON.stringify({ "ci": this.state.search });
    AdminDataService.listUserWS(data).then(response => {
      this.setState({ "loading": false });
      this.setState({ "wsEstudiante": response.data.data.wsEstudiante })
      this.setState({ "wsDocenteP": response.data.data.wsDocenteP })
      this.setState({ "wsDocenteS": response.data.data.wsDocenteS })
      this.setState({ "wsAdminP": response.data.data.wsAdminP })
      this.setState({ "wsAdminS": response.data.data.wsAdminS })
    }).catch(e => {
      try {
        this.setState({ "loading": false });
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    })
    event.preventDefault();
  }
  handleChange = (event) => {
    this.setState({ "search": event.target.value });
  }
  render() {
    return (
      <Card elevation={3}>
        <CardContent>
          <form onSubmit={this.handleSubmit}>
            <TextField onChange={this.handleChange} name="ci" fullWidth id="search" label="CI" variant="outlined" required
              helperText="Ingrese Carnet de Identidad"
              InputProps={{
                endAdornment: (
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                )
              }}
            />{this.state.loading && <CircularProgress size={24} />}
          </form>
          {
            this.state.wsEstudiante !== null ?
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography >WEB SERVICE ESTUDIANTE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <List>
                        <ListItem>
                          <ListItemText primary={`${this.state.wsEstudiante.paterno} ${this.state.wsEstudiante.materno}  ${this.state.wsEstudiante.nombres}  -  CI: ${this.state.wsEstudiante.ci}  -  Fecha Nac: ${new Date(this.state.wsEstudiante.nacimiento.$date).getUTCDate()}/${new Date(this.state.wsEstudiante.nacimiento.$date).getUTCMonth()+1}/${new Date(this.state.wsEstudiante.nacimiento.$date).getUTCFullYear()}`} />
                        </ListItem>
                      {this.state.wsEstudiante.carreras.map((user, key) => (
                        <ListItem key={key} style={new Date().getFullYear() === new Date(user.fechaRegistro.$date).getFullYear() ? {  backgroundColor: green[300]} : null}>
                          <ListItemAvatar>
                            <Avatar>{user.grado}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={`RU : ${user.ru}`} secondary={`Fecha pago matrícula:  ${new Date(user.fechaRegistro.$date)} - Programa: ${user.programa}`} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                </AccordionDetails>

              </Accordion>
              : null
          }
          {
            this.state.wsDocenteP !== null ?
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography >WEB SERVICE DOCENTE - PRIMARIO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <List>
                        <ListItem>
                          <ListItemText primary={`${this.state.wsDocenteP.paterno} ${this.state.wsDocenteP.materno}  ${this.state.wsDocenteP.nombres}  -  CI: ${this.state.wsDocenteP.ci}`} secondary= {` Fecha Nac: ${new Date(this.state.wsDocenteP.nacimiento.$date).getUTCDate()}/${new Date(this.state.wsDocenteP.nacimiento.$date).getUTCMonth()+1}/${new Date(this.state.wsDocenteP.nacimiento.$date).getUTCFullYear()} - NUA: ${this.state.wsDocenteP.nua}`}/>
                        </ListItem>
                      {this.state.wsDocenteP.carreras.map((user, key) => (
                        <ListItem key={key}>                          
                          <ListItemText primary={user}/>
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                </AccordionDetails>

              </Accordion>
              : null
          }
          {
            this.state.wsDocenteS !== null ?
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography >WEB SERVICE DOCENTE - SECUNDARIO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <List>
                        <ListItem>
                          <ListItemText primary={`${this.state.wsDocenteS.paterno} ${this.state.wsDocenteS.materno}  ${this.state.wsDocenteS.nombres}  -  CI: ${this.state.wsDocenteS.ci}`}  secondary= {` Fecha Nac: ${new Date(this.state.wsDocenteS.nacimiento.$date).getUTCDate()}/${new Date(this.state.wsDocenteS.nacimiento.$date).getUTCMonth()+1}/${new Date(this.state.wsDocenteS.nacimiento.$date).getUTCFullYear()}  - ${this.state.wsDocenteS.permanente?"PERMANENTE":"NO ES PERMANENTE"} `} />
                        </ListItem>
                    </List>
                  </Box>

                </AccordionDetails>

              </Accordion>
              : null
          }
          {
            this.state.wsAdminP !== null ?
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography >WEB SERVICE ADMINISTRATIVO - PRIMARIO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <List>
                        <ListItem>
                          <ListItemText primary={`${this.state.wsAdminP.paterno} ${this.state.wsAdminP.materno}  ${this.state.wsAdminP.nombres}  -  CI:  ${this.state.wsAdminP.ci} `} secondary= {` Fecha Nac: ${new Date(this.state.wsAdminP.nacimiento.$date).getUTCDate()}/${new Date(this.state.wsAdminP.nacimiento.$date).getUTCMonth()+1}/${new Date(this.state.wsAdminP.nacimiento.$date).getUTCFullYear()}   - ${this.state.wsAdminP.planta?"PLANTA":"NO ES PLANTA"} `}/>
                        </ListItem>
                    </List>
                  </Box>

                </AccordionDetails>

              </Accordion>
              : null
          }
          {
            this.state.wsAdminS !== null ?
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography >WEB SERVICE ADMINISTRATIVO - SECUNDARIO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <List>
                        <ListItem>
                          <ListItemText primary={`${this.state.wsAdminS.paterno} ${this.state.wsAdminS.materno}  ${this.state.wsAdminS.nombres}  -  CI: ${this.state.wsAdminS.ci}  `} secondary= {` Fecha Nac: ${new Date(this.state.wsAdminS.nacimiento.$date).getUTCDate()}/${new Date(this.state.wsAdminS.nacimiento.$date).getUTCMonth()+1}/${new Date(this.state.wsAdminS.nacimiento.$date).getUTCFullYear()}  - ${this.state.wsAdminS.planta?"PLANTA":"NO ES PLANTA"} `}/>
                        </ListItem>
                    </List>
                  </Box>

                </AccordionDetails>

              </Accordion>
              : null
          }
        </CardContent>

        {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}
      </Card>
    )
  }
}
export default withStyles(useStyles)(SearchServices);