import React from 'react';
import Typography from '@material-ui/core/Typography';
import VerificationResponsableForm from "views/Verification/VerificationResponsableForm";

function VerificationResponsablePage(){

  return (
    <div>
      <Typography variant="h2"   gutterBottom>
        Bienvenido
      </Typography>
      <Typography variant="h6" gutterBottom>
        Necesitamos el Carnet de Identidad del responsable de la cuenta
      </Typography>
      <VerificationResponsableForm/>
    </div>
  );
}
export default VerificationResponsablePage;