import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Send from '@material-ui/icons/Send';

import Snackbar from "components/Snackbars/Snackbar";

import { Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import UsuariosDataService from "services/usuarios.service";
class VerificationResponsableForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { "form": { "ci": "" }, "status": { "show": false, "redirect": false, "msg": "", "status": "success", "next": "/" }, "loading": false };
  }
  handleClearSnack = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.status.show = false;
    statusCopy.status.msg = "";
    statusCopy.status.status = "success";
    this.setState(statusCopy);
  }

  handleChange = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState(statusCopy);
  }

  handleSubmit = (event) => {
    this.handleClearSnack(event);
    var data = JSON.stringify(this.state.form);
    this.setState({ "loading": true })
    UsuariosDataService.verifyResponsable(data)
      .then(response => {
        if (response.data.code === 1) {
          let statusCopy = Object.assign({}, this.state);
          statusCopy.status.show = true;
          statusCopy.status.msg = response.data.message;
          statusCopy.status.status = "success";
          statusCopy.status.redirect = true;
          statusCopy.status.next = response.data.next.replace("/api/", "/");
          this.setState(statusCopy)
        } else {
          let statusCopy = Object.assign({}, this.state);
          statusCopy.status.show = true;
          statusCopy.status.msg = response.data.message;
          statusCopy.status.status = "error";
          this.setState(statusCopy)
        }
        this.setState({ "loading": false })
      })
      .catch(e => {
        this.setState({ "loading": false })
        if (e.response.status === 401) {
          let statusCopy = Object.assign({}, this.state);
          statusCopy.status.show = true;
          statusCopy.status.msg = e.response.data.message;
          statusCopy.status.status = "error";
          statusCopy.status.redirect = true;
          statusCopy.status.next = "/";
          this.setState(statusCopy)
        }
        console.log(e);
      })
    event.preventDefault();
  }

  render() {
    if (this.state.status.redirect) {
      return <Redirect push to={this.state.status.next} />
    }

    return (
      <form autoComplete="off" onSubmit={this.handleSubmit}>
        <Card elevation={3}>
          <CardContent>
            <TextField onChange={this.handleChange} name="ci" fullWidth id="standard-basic" label="Carnet de Identidad Responsable" variant="outlined"
              helperText="Número de identidad sin extensión"
            />
          </CardContent>
          <CardActions>
            <Button fullWidth
              disabled={this.state.loading}
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<Send />}
            >
              Continuar
                </Button>
            {this.state.loading && <CircularProgress size={24} />}
          </CardActions>
          {this.state.status.show ? <Snackbar msg={this.state.status.msg} status={this.state.status.status} clear={this.handleClearSnack} /> : null}
        </Card>
      </form>
    );
  }
}

export default VerificationResponsableForm;