import React from 'react';
import http from "../http-common";
import AdminUsers from "../views/Admin/AdminItemUsers";
import AdminPermissions from "../views/Admin/AdminItemPermissions";
import AdminLogs from "../views/Admin/AdminItemLogs";
import Admin2FA from "../views/Admin/AdminItem2FA";
import WhiteList from "../views/Admin/AdminItemWhiteList";
import BlackList from "../views/Admin/AdminItemBlackList";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ScreenLockPortraitIcon from '@material-ui/icons/ScreenLockPortrait';
import SecurityIcon from '@material-ui/icons/Security';
import TocIcon from '@material-ui/icons/Toc';
import PeopleIcon from '@material-ui/icons/People';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import WSAdmin from 'views/Admin/AdminItemWSAdmin';
import SearchIcon from '@material-ui/icons/Search';
import WSDoc from 'views/Admin/AdminItemWSDoc';
import SearchServices from "../views/Admin/SearchWebServices";

import  {isValid, parseISO} from 'date-fns'
import AdminItemUsersDocentes from 'views/Admin/AdminItemUsersDocentes';
import AdminItemUsersStudents from 'views/Admin/AdminItemUsersEstudents';
class AdminDataService{
    login(data){
        return http.post("/api/admin/login",data);
    }
    dashboard(){
        return http.post("api/admin/dashboard",{},this.getAuthToken());
    }
    logout(){
        return http.post("/api/logout",{},this.getAuthToken());
    }
    clearSession(){
        localStorage.removeItem("currentUserToken");
        localStorage.removeItem("currentUserExpires");
    }
    listLogs(data){
        return http.post("/api/admin/search/logs",data,this.getAuthToken());
    }
    list2FA(data){
        return http.post("/api/admin/2fa",data,this.getAuthToken());
    }
    delete2FA(id){
        return http.delete(`/api/admin/2fa/${id}`,this.getAuthToken());
    }
    listUsers(data){
        return http.post("/api/admin/search/ad",data,this.getAuthToken());
    }
    listGUsers(data){
        return http.post("/api/admin/search/google",data,this.getAuthToken());
    }
    resetUser(data){
        return http.post("/api/admin/reset",data,this.getAuthToken());
    }
    listUsersDocente(data){
        return http.post("/api/admin/search/ad-docente",data,this.getAuthToken());
    }
    resetUserDocente(data){
        return http.post("/api/admin/reset-docente",data,this.getAuthToken());
    }
    listUsersStudent(data){
        return http.post("/api/admin/search/ad-student",data,this.getAuthToken());
    }
    resetUserStudent(data){
        return http.post("/api/admin/reset-student",data,this.getAuthToken());
    }
    listPermissions(){
        return {
            "list-ad": {"text": "Listar Usuarios","tab": true, "view": AdminUsers , "icon": <AccountCircleIcon/> },
            "crud-permission": {"text": "Administrar Permisos", "tab": true, "view": AdminPermissions, "icon": <SecurityIcon/> },
            "list-logs": {"text": "Listar Historial", "tab": true, "view": AdminLogs, "icon": <TocIcon/> },
            "list-google": {"text": "Usuarios Google", "tab": false},
            "reset-ad": {"text": "Reset Cuenta", "tab": false},
            "rd-2fa": {"text": "Administrar 2FA", "tab": true, "view": Admin2FA, "icon": <ScreenLockPortraitIcon/> },
            "lot-migrate-whitelist": {"text": "Administrar WhiteList", "tab": true, "view": WhiteList, "icon": <PeopleIcon/> },
            "lot-migrate-blacklist": {"text": "Administrar BlackList", "tab": true, "view": BlackList, "icon": <PeopleOutlineIcon/> },
            "institucional-account-edit": {"text": "Actualizar Cuentas Institucionales - UID", "tab": false},
            "signout-google": {"text": "Cerrar Sesiones Google", "tab": false},
            "crud-ad": {"text": "Crear y Actualizar Cuentas Institucionales", "tab": false},
            "lot-admin": {"text": "Administrar Administrativos", "tab": true, "view": WSAdmin, "icon": <SupervisorAccountIcon/> },
            "lot-doc": {"text": "Administrar Docentes", "tab": true, "view": WSDoc, "icon": <SupervisorAccountIcon/> },
            "search-ws": {"text": "Consulta Webservices", "tab": true, "view": SearchServices, "icon": <SearchIcon/> },
            "list-ad-doc": {"text": "Listar Usuarios (Docentes)","tab": true, "view": AdminItemUsersDocentes, "icon": <AccountCircleIcon/> },
            "reset-ad-doc": {"text": "Reset Cuenta (Docentes)", "tab": false},
            "list-ad-est": {"text": "Listar Usuarios (Estudiante)","tab": true, "view": AdminItemUsersStudents, "icon": <AccountCircleIcon/> },
            "reset-ad-est": {"text": "Reset Cuenta (Estudiante)", "tab": false},
        };
    }
    listPermissionsLookup(){
        return {
            "list-ad":  "Listar Usuarios",
            "crud-permission": "Administrar Permisos",
            "list-google": "Usuarios Google",
            "reset-ad": "Reset Cuenta",
            "list-logs": "Listar Historial",
            "rd-2fa": "Administrar 2FA",
            "lot-migrate-whitelist": "Administrar WhiteList",
            "lot-migrate-blacklist": "Administrar BlackList",
            "institucional-account-edit": "Actualizar Cuentas Institucionales - UID",
            "signout-google": "Cerrar Sesiones Google",
            "crud-ad": "Crear y Actualizar Cuentas Institucionales",
            "lot-admin": "Administrar Administrativos",
            "lot-doc": "Administrar Docentes",
            "search-ws": "Consulta Webservices",
            "list-ad-doc":  "Listar Usuarios (Docentes)",
            "reset-ad-doc": "Reset Cuenta (Docentes)",
            "list-ad-est":  "Listar Usuarios (Estudiantes)",
            "reset-ad-est": "Reset Cuenta (Estudiantes)",
        };
    }
    listLogsLookup(){
        return {
            "login":  "Ingreso",
            "verification_ru":  "Verificación RU",
            "verification_birthday":  "Verificación Fecha Nac.",
            "verification_responsable":  "Verificación Responsable",
            "verification_password":  "Verificación Password",
            "register_account":  "Registrar Cuenta",
            "edit_account":  "Editar Cuenta",
            "activate_account":  "Activar Cuenta",
            "migrate_gsuit_account":  "Migrar Cuenta",
            "edit_password":  "Cambiar Contraseña",
            "recover_password":  "Recuperar Contraseña",
            "reset_admin":  "Reset Cuenta",
            "default":  "Default",
            "otp_verify": "Verificado de 2FA",
            "otp_disable": "Deshabilitado de 2FA",
            "refresh_uid": "Actualizar Código UID",
            "sign_out_google": "Cerrar Sesion Google",
            "admin_create_account": "Administrador Crear Cuenta Institucional",
            "admin_edit_account": "Administrador Editar Cuenta Institucional",
            "admin_edit_account_google": "Administrador Cuenta Google"
        };
    }
    listEstamentoLookup(){
        return {
            "estudiante":  "Estudiante",
            "docente":  "Docente",
            "administrativo":  "Administrativo",
            "institucional":  "Institucional",
            "temporal": "Temporal"
        };
    }
    listEstamentoArray(){
        return ["estudiante", "docente", "administrativo", "institucional","temporal"]
    }
    listPermissionsArray(option=null){
        const list = [
            { "id":  "list-ad", "name": "Listar Usuarios"},
            { "id":  "crud-permission", "name": "Administrar Permisos"},
            { "id":  "list-google", "name": "Usuarios Google"},
            { "id":  "reset-ad", "name": "Reset Cuenta"},
            { "id":  "list-logs", "name": "Listar Historial"},
            { "id":  "rd-2fa", "name": "Administrar 2FA"},
            { "id":  "lot-migrate-whitelist", "name": "Administrar Whitelist"},
            { "id":  "lot-migrate-blacklist", "name": "Administrar BlackList"},
            { "id":  "institucional-account-edit", "name": "Actualizar Cuentas Institucionales - UID"},
            { "id":  "signout-google", "name": "Cerrar Sesiones Google"},
            { "id":  "crud-ad", "name": "Crear y Actualizar Cuentas Institucionales"},
            { "id":  "lot-admin", "name": "Administrar Administrativos"},
            { "id":  "lot-doc", "name": "Administrar Docentes"},
            { "id":  "search-ws", "name": "Consulta Webservices"},
            { "id":  "list-ad-doc", "name": "Listar Usuarios (Docentes)"},
            { "id":  "reset-ad-doc", "name": "Reset Cuenta (Docentes)"},
            { "id":  "list-ad-est", "name": "Listar Usuarios (Estudiantes)"},
            { "id":  "reset-ad-est", "name": "Reset Cuenta (Estudiantes)"},            
        ]
        if (option === null){
            return list;
        }else{
            const find = list.find(x => x.id === option);
            if (find !== undefined){
                return [find]
            }else{
                return list
            }
        }
    }
    listUserPermissions(data){
        return http.get("/api/admin/permissions",this.getAuthToken(data));
    }
    addUserPermissions(data){
        return http.post("/api/admin/permissions",data,this.getAuthToken());
    }
    editUserPermissions(data,id){
        return http.put(`/api/admin/permissions/${id}`,data,this.getAuthToken());
    }
    deleteUserPermissions(id){
        return http.delete(`/api/admin/permissions/${id}`,this.getAuthToken());
    }
    getUserPermissions(id){
        return http.get(`/api/admin/permissions/${id}`,this.getAuthToken());
    }
    addLotWhiteList(data,aditionalConfig=null){
        return http.post("/api/admin/whitelist/lot",data,this.getAuthToken(null,"multipart/form-data",aditionalConfig));
    }
    listLotWhiteList(){
        return http.get("/api/admin/whitelist/lot",this.getAuthToken());
    }
    deleteWhiteListLot(data){
        return http.delete("/api/admin/whitelist/lot",this.getAuthToken(data));
    }
    listLotWhiteListItem(data){
        return http.get("/api/admin/whitelist",this.getAuthToken(data));
    }
    deleteWhiteListItem(data){
        return http.delete(`/api/admin/whitelist`,this.getAuthToken(data));
    }
    addLotBlackList(data,aditionalConfig=null){
        return http.post("/api/admin/blacklist/lot",data,this.getAuthToken(null,"multipart/form-data",aditionalConfig));
    }
    listLotBlackList(){
        return http.get("/api/admin/blacklist/lot",this.getAuthToken());
    }
    deleteBlackListLot(data){
        return http.delete("/api/admin/blacklist/lot",this.getAuthToken(data));
    }
    listLotBlackListItem(data){
        return http.get("/api/admin/blacklist",this.getAuthToken(data));
    }
    deleteBlackListItem(data){
        return http.delete(`/api/admin/blacklist`,this.getAuthToken(data));
    }
    refreshUIDInstitucionalAccount(data){
        return http.post("/api/admin/institucional/uid/refresh",data,this.getAuthToken());
    }
    signOutGoogle(data){
        return http.post("/api/admin/signout/google",data,this.getAuthToken());
    }
    listGroups(){
        return http.get("/api/admin/groups",this.getAuthToken());
    }
    insertAccount(data){
        return http.post("/api/admin/user/ad",data,this.getAuthToken());
    }
    updateAccount(data){
        return http.put("/api/admin/user/ad",data,this.getAuthToken());
    }
    getAccount(data){
        return http.get("/api/admin/user/ad",this.getAuthToken(data));
    }
    addWSAdmin(data,aditionalConfig=null){
        return http.post("/api/admin/service/admin",data,this.getAuthToken(null,"multipart/form-data",aditionalConfig));
    }
    listUserGroups(data){
        return http.get("/api/admin/user/groups",this.getAuthToken(data));
    }
    updateUserGroup(data){
        return http.put("/api/admin/user/groups",data,this.getAuthToken());
    }
    deleteUserGroup(data){
        return http.delete("/api/admin/user/groups",this.getAuthToken(data));
    }
    listWSAdmin(){
        return http.get("/api/admin/service/admin",this.getAuthToken());
    }
    listWSDoc(){
        return http.get("/api/admin/service/doc",this.getAuthToken());
    }
    addWSDoc(data,aditionalConfig=null){
        return http.post("/api/admin/service/doc",data,this.getAuthToken(null,"multipart/form-data",aditionalConfig));
    }
    deleteWSDoc(data){
        return http.delete("/api/admin/service/doc",this.getAuthToken(data));
    }
    listUserWS(data){
        return http.post("/api/admin/search/webservices",data,this.getAuthToken());
    }
    listUserResponsables(data){
        return http.get("/api/admin/user/responsables",this.getAuthToken(data));
    }
    getAuthToken(params=null, contentType="application/json", aditionalConfig=null){

        var defaultConf = {
            headers: {
                "Content-type": contentType
            }
        }
        if (params!= null){
            defaultConf.params = params
        }
        if (aditionalConfig !== null){
            for (const key in aditionalConfig) {
                if (aditionalConfig.hasOwnProperty(key)) {
                    defaultConf[key] = aditionalConfig[key]
                }
            }
        }

        var token = localStorage.getItem('currentUserToken');
        var expires = localStorage.getItem('currentUserExpires');
        if (expires === null || expires === undefined){
            return defaultConf
        }
        var expireDate = parseISO(expires)
        var validTime = isValid(expireDate)
        if (validTime && Date.now() <= expireDate.getTime()){
            var confs =  {
                headers: {
                    "Content-type": contentType,
                    'Authorization': `Bearer ${token}`
                }
            }
            if (params!= null){
                confs.params = params
            }
            if (aditionalConfig !== null){
                for (const key in aditionalConfig) {
                    if (aditionalConfig.hasOwnProperty(key)) {
                        confs[key] = aditionalConfig[key]
                    }
                }
            }
            return confs;
        }else{
            this.clearSession();
            return defaultConf;
        }
    }
    listUserObservations(data){
        return http.get("/api/admin/user/observations",this.getAuthToken(data));
    }
    isLoggedIn(){
        var expires = localStorage.getItem('currentUserExpires');
        if (expires === null || expires === undefined){
            return false
        }
        var expireDate = parseISO(expires)
        var validTime = isValid(expireDate)
        if (validTime && Date.now() >= expireDate.getTime()){
            this.clearSession();
            return false;
        }else{
            return true;
        }
    }
}
export default new AdminDataService();