import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid, IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import tableIcons from "../utils/TableIcons"
import Delete from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AdminDataService from "../../services/admin.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "components/Snackbars/Snackbar";
import Autocomplete from '@material-ui/lab/Autocomplete';
import GetAppIcon from '@material-ui/icons/GetApp';
const useStyles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(2)
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    "z-index": 11
  }
});

class WhiteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "rowsLot": [], "rowsLotItem": [], "loading": false, "form": { "search": "" },
      "idDelete": null, "openDeleteItem": false, "openDeleteLot": false, "show": false, "message": "", "status": "success",
      "openDialogInsertLot": false, "lot": { "type": "estudiante", "file": null },
      "uploading": false, "uploadingPercent": 0
    }
    this.handleReloadDataGrid();
  }
  handleReloadDataGrid = () => {
    AdminDataService.listLotWhiteList().then(response => {
      if (response.data.code === 1) {
        const rows = []
        for (const i of response.data.data) {
          let item = {}
          item["id"] = i;
          item["date"] = new Date(i);
          rows.push(item);
        }
        this.setState({ "rowsLot": rows })
      }
    }).catch(e => {
      console.log(e)
    });
  }

  handleViewLot = (id) => {
    console.log(id)
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form["search"] = id
    this.setState({ "form": statusCopy.form });
    this.handleSearch();
  }

  handleChangeSearch = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
  }
  handleSetFileLot = (event) => {
    console.log(event.target.files);
    let statusCopy = Object.assign({}, this.state);
    if (event.target.files.length !== 0) {
      statusCopy.lot.file = event.target.files
    }
    else {
      statusCopy.lot.file = null
    }
    this.setState({ "lot": statusCopy.lot });

  }
  handleSetValueEstamento = (id) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.lot.type = id
    this.setState({ "lot": statusCopy.lot });
  }

  handleSubmitSearch = (event) => {
    this.handleSearch();
    event.preventDefault();
  }
  handleClearSnack = (event) => {
    this.setState({ "show": false })
  }

  handleSearchClear = () => {
    this.setState({ "form": { "search": "" } })
    this.setState({ "rowsLotItem": [] })
  }
  handleSearch = () => {
    this.setState({ "loading": true });
    var data = this.state.form;
    AdminDataService.listLotWhiteListItem(data).then(response => {
      if (response.data.code === 1) {
        const rows = []
        for (const item of response.data.data) {
          item["id"] = item._id.$oid;
          item["date"] = new Date(item.registerDate);
          rows.push(item);
        }
        this.setState({ "rowsLotItem": rows })
        this.setState({ "loading": false });
      }
    }).catch(e => {
      console.log(e)
      this.setState({ "loading": false });
    });
  }
  handleCloseDialogDeleteItem = () => {
    this.setState({ "openDeleteItem": false })
  }
  handleCloseDialogDeleteLot = () => {
    this.setState({ "openDeleteLot": false })
  }
  handleDeleteItem = (id) => {
    this.setState({ "idDelete": id })
    this.setState({ "openDeleteItem": true })
  }
  handleDeleteLot = (id) => {
    this.setState({ "idDelete": id })
    this.setState({ "openDeleteLot": true })
  }

  handleOpenDialogInsertLot = () => {
    // if (this.state.permission.id !== null){
    //   // this.setState({ "permission": this.defaultPermission() });
    // }
    this.setState({ "openDialogInsertLot": true })
  };
  handleCloseDialogInsertLot = () => {
    this.setState({ "openDialogInsertLot": false })
  }

  handleSubmitLot = (event) => {
    event.preventDefault();
    if (this.state.lot.file === null) {
      this.setState({ "show": true })
      this.setState({ "msg": "Seleccione almenos 1 Archivo para subir" })
      this.setState({ "status": "error" })
      return;
    }
    this.setState({ "uploading": true });
    const formData = new FormData();
    for (const file of this.state.lot.file) {
      formData.append("file", file);
    }
    formData.append("type", this.state.lot.type);
    this.handleCloseDialogInsertLot()
    this.setState({ "uploadingPercent": 0 })
    const aditionalConfig = {
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.setState({ "uploadingPercent": percentCompleted })
      }
    }
    AdminDataService.addLotWhiteList(formData, aditionalConfig).then(response => {
      this.setState({ "uploading": false });
      this.setState({ "lot": { "type": "estudiante", "file": null } })
      if (response.data.code === 1) {
        this.handleReloadDataGrid();
        this.handleSearchClear();
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.code === 1 ? response.data.message : response.data.message + " - " + response.data.files })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        this.setState({ "uploading": false });
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    });
  }

  handleSubmitDeleteLot = (id) => {
    this.handleCloseDialogDeleteLot();
    AdminDataService.deleteWhiteListLot({ "lot": id }).then(response => {
      if (response.data.code === 1) {
        this.setState({ "idDelete": null })
        this.handleReloadDataGrid();
        this.handleSearch();
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    });
  }
  handleSubmitDeleteItem = (id) => {
    this.handleCloseDialogDeleteItem();
    AdminDataService.deleteWhiteListItem({ "id": id }).then(response => {
      if (response.data.code === 1) {
        this.setState({ "idDelete": null })
        this.handleSearch();
      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          AdminDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Card elevation={3}>
          <CardContent>
            <Box>
              <MaterialTable
                icons={tableIcons}
                columns={[
                  { title: 'Fecha', field: 'date', type: "datetime", filtering: false },
                  { title: 'ID', field: 'id', type: 'numeric' },
                ]}
                data={this.state.rowsLot}
                title="Lotes WhiteList"
                options={{
                  filtering: true
                }}
                actions={[
                  {
                    icon: () => <Delete />,
                    tooltip: 'Borrar',
                    onClick: (event, rowData) => this.handleDeleteLot(rowData.id)
                  },
                  {
                    icon: () => <VisibilityIcon />,
                    tooltip: 'Ver Lote',
                    onClick: (event, rowData) => this.handleViewLot(rowData.id)
                  }
                ]}
              />
            </Box>
            <br></br>
            <Divider />
            <br></br>
            <Box>
              <form onSubmit={this.handleSubmitSearch}>
                <TextField onChange={this.handleChangeSearch} name="search" fullWidth id="search" label="ID, CI, Facultad" variant="outlined" required
                  helperText="Ingrese ID, CI o Facultad" value={this.state.form.search}
                  InputProps={{
                    endAdornment: (
                      <IconButton type="submit" className={classes.iconButton} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    )
                  }}
                />{this.state.loading && <CircularProgress size={24} />}
              </form>
              <MaterialTable
                icons={tableIcons}
                columns={[
                  { title: 'ID', field: 'id', hidden: true },
                  { title: 'CI', field: 'ci' },
                  { title: 'Estamento', field: 'type', lookup: AdminDataService.listEstamentoLookup() },
                  { title: 'Facultad', field: 'facultad' },
                  { title: 'Migrado', field: 'migrated', type: "boolean" },
                  { title: 'Fecha', field: 'date', type: "datetime", filtering: false },
                  { title: 'ID Lote', field: 'registerDate', type: 'numeric' },
                ]}
                data={this.state.rowsLotItem}
                title="WhiteList por registro"
                options={{
                  filtering: true
                }}
                actions={[
                  {
                    icon: () => <Delete />,
                    tooltip: 'Borrar',
                    onClick: (event, rowData) => this.handleDeleteItem(rowData.id)
                  }
                ]}
              />
            </Box>
          </CardContent>
          <Fab color="primary" aria-label="add" variant="extended" className={classes.fab} onClick={this.handleOpenDialogInsertLot} disabled={this.state.uploading}>
            {this.state.uploading ? <CircularProgress size={24} variant="static" value={this.state.uploadingPercent} /> : <AddIcon className={classes.extendedIcon} />}
              Adicionar Lote
          </Fab>
          <Dialog
            open={this.state.openDeleteLot && this.state.idDelete !== null}
            onClose={this.handleCloseDialogDeleteLot}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Eliminar el Lote?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Aceptar para confirmar la eliminación del Lote en la WhiteList"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={e => this.handleSubmitDeleteLot(this.state.idDelete)} color="primary" >
                Aceptar
                </Button>
              <Button onClick={this.handleCloseDialogDeleteLot} color="secondary" autoFocus>
                Cancelar
                </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openDeleteItem && this.state.idDelete !== null}
            onClose={this.handleCloseDialogDeleteItem}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Eliminar el registro?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Aceptar para confirmar la eliminación del registro en la WhiteList"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={e => this.handleSubmitDeleteItem(this.state.idDelete)} color="primary" >
                Aceptar
                </Button>
              <Button onClick={this.handleCloseDialogDeleteItem} color="secondary" autoFocus>
                Cancelar
                </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openDialogInsertLot}
            onClose={this.handleCloseDialogInsertLot}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <form onSubmit={this.handleSubmitLot}>
              <DialogTitle id="alert-dialog-title">{"Insertar Lote"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Seleccione para subir archivos en formato csv, delimitados por ','. Si sube más de un archivo cada uno sera considerado como un lote nuevo por separado"}
                </DialogContentText>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <input id="fileUploadButton" type="file" hidden onChange={this.handleSetFileLot} multiple accept=".csv" required></input>
                    <label htmlFor={'fileUploadButton'}>
                      <Fab
                        color="secondary"
                        size="small"
                        component="span"
                        aria-label="add"
                        variant="extended"
                      >
                        <AddIcon /> {this.state.lot.file === null ? "Seleccionar archivos csv" :
                          this.state.lot.file.length === 1 ? `${this.state.lot.file.length} Archivo seleccionado` : `${this.state.lot.file.length} Archivos seleccionados`}
                      </Fab>
                    </label>

                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Fab
                      color="primary"
                      size="small"
                      component="a"
                      aria-label="add"
                      variant="extended"
                      href="/templateWhitelist.csv" download
                    >
                      <GetAppIcon /> {"Descargar plantilla csv"}
                    </Fab>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      value={this.state.lot.type}
                      onChange={(event, newValue) => {
                        // if (newValue !== null) {
                        this.handleSetValueEstamento(newValue);
                        // }
                      }}
                      id="type"
                      options={AdminDataService.listEstamentoArray()}
                      renderInput={(params) => <TextField {...params} label="Estamento" variant="outlined" required />}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleSubmitLot} color="primary" >
                  Aceptar
                </Button>
                <Button onClick={this.handleCloseDialogInsertLot} color="secondary" autoFocus>
                  Cancelar
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Card>
        {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}
      </div>
    )
  }
}
// @ts-ignore
export default withStyles(useStyles)(WhiteList);