import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import tableIcons from "../utils/TableIcons";
import AdminDataService from "../../services/admin.service";
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import Snackbar from "components/Snackbars/Snackbar";
import Delete from '@material-ui/icons/Delete';
const useStyles = theme => ({
    extendedIcon: {
        marginRight: theme.spacing(2)
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        "z-index": 11
    }
});

class WSDoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "rowsLot": [],
            "openDialog": false,
            "files": null,
            "uploading": false,
            "uploadingPercent": 0,
            "show": false,
            "msg": "",
            "status": "success",
            "openDelete": false, "idDelete": null
        }
        this.handleReloadDataGrid();
    }
    handleClearSnack = (event) => {
        this.setState({ "show": false })
    }
    handleReloadDataGrid = () => {
        AdminDataService.listWSDoc().then(response => {
            if (response.data.code === 1) {
                const rows = []
                for (const item of response.data.data) {
                    item["id"] = item._id.$oid;
                    item["date"] = new Date(item.fecnac);
                    rows.push(item);
                }
                this.setState({ "rowsLot": rows })
            }
        }).catch(e => {
            console.log(e)
        });
    }
    handleOpenDialog = () => {
        this.setState({ "openDialog": true })
    };
    handleCloseDialog = () => {
        this.setState({ "openDialog": false })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.files === null) {
            this.setState({ "show": true })
            this.setState({ "msg": "Seleccione almenos 1 Archivo para subir" })
            this.setState({ "status": "error" })
            return;
        }
        const formData = new FormData();
        for (const file of this.state.files) {
            formData.append("file", file);
        }
        this.setState({ "uploading": true });
        this.setState({ "uploadingPercent": 0 })
        const aditionalConfig = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ "uploadingPercent": percentCompleted })
            }
        }
        this.handleCloseDialog();
        AdminDataService.addWSDoc(formData, aditionalConfig).then(response => {
            this.setState({ "uploading": false });
            this.setState({ "files": null });
            if (response.data.code === 1) {
                this.handleReloadDataGrid();
            }
            this.setState({ "show": true })
            this.setState({ "msg": response.data.message })
            if (response.data.files !== undefined) {
                this.setState({ "msg": response.data.message + " - " + response.data.files })
            }
            this.setState({ "status": response.data.code === 1 ? "success" : "error" })
        }).catch(e => {
            try {
                this.setState({ "uploading": false });
                if (e.response.status === 403 || e.response.status === 401) {
                    AdminDataService.logout();
                    this.props.logout();
                }
            } catch (error) {
                console.log(error)
            }
        });
    }
    handleSetFile = (event) => {
        if (event.target.files.length !== 0) {
            this.setState({ "files": event.target.files });
        }
        else {
            this.setState({ "files": null });
        }
    }

    handleDeletePermission = (id) => {
        this.setState({ "idDelete": id })
        this.setState({ "openDelete": true })
    }

    handleCloseDialogDelete = () => {
        this.setState({ "openDelete": false })
    }

    handleSubmitDelete = (id) => {
        this.handleCloseDialogDelete();
        AdminDataService.deleteWSDoc({ "id": id }).then(response => {
            if (response.data.code === 1) {
                this.setState({ "idDelete": null })
                this.handleReloadDataGrid();

            }
            this.setState({ "show": true })
            this.setState({ "msg": response.data.message })
            this.setState({ "status": response.data.code === 1 ? "success" : "error" })
        }).catch(e => {
            try {
                if (e.response.status === 403 || e.response.status === 401) {
                    AdminDataService.logout();
                    this.props.logout();
                }
            } catch (error) {
                console.log(error)
            }
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Card elevation={3}>
                <CardContent>
                    <Box>
                        <MaterialTable
                            icons={tableIcons}
                            columns={[
                                { title: 'ID', field: 'id', hidden: true },
                                { title: 'CI', field: 'ci' },
                                { title: 'Paterno', field: 'paterno' },
                                { title: 'Materno', field: 'materno' },
                                { title: 'Nombres', field: 'nombres' },
                                { title: 'Fecha Nacimiento', field: 'date', type: "date", filtering: false },
                                { title: 'Tipo', field: 'tipo' },
                            ]}
                            data={this.state.rowsLot}
                            title="Docentes"
                            options={{
                                filtering: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 30]
                            }}
                            actions={[
                                {
                                    icon: () => <Delete />,
                                    tooltip: 'Borrar',
                                    onClick: (event, rowData) => this.handleDeletePermission(rowData.id)
                                }
                            ]}
                        />
                    </Box>
                </CardContent>
                <Fab color="primary" aria-label="add" variant="extended" className={classes.fab} onClick={this.handleOpenDialog} disabled={this.state.uploading}>
                    {this.state.uploading ? <CircularProgress size={24} variant="static" value={this.state.uploadingPercent} /> : <AddIcon className={classes.extendedIcon} />}
                    {"Cargar Personal"}
                </Fab>
                <Dialog
                    open={this.state.openDelete && this.state.idDelete !== null}
                    onClose={this.handleCloseDialogDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Eliminar el Registro?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {"Aceptar para confirmar la eliminación del Registro de Docente"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => this.handleSubmitDelete(this.state.idDelete)} color="primary" >
                            Aceptar
                </Button>
                        <Button onClick={this.handleCloseDialogDelete} color="secondary" autoFocus>
                            Cancelar
                </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <form onSubmit={this.handleSubmit}>
                        <DialogTitle id="alert-dialog-title">{"Insertar Lote"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Seleccione para subir archivos en formato csv, delimitados por ','. Si sube más de un archivo cada uno sera considerado como un lote nuevo por separado"}
                            </DialogContentText>
                            <Grid container alignItems="center" spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <input id="fileUploadButton" type="file" hidden onChange={this.handleSetFile} multiple accept=".csv" required></input>
                                    <label htmlFor={'fileUploadButton'}>
                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"
                                        >
                                            <AddIcon /> {this.state.files === null ? "Seleccionar archivos csv" :
                                                this.state.files.length === 1 ? `${this.state.files.length} Archivo seleccionado` : `${this.state.files.length} Archivos seleccionados`}
                                        </Fab>
                                    </label>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Fab
                                        color="primary"
                                        size="small"
                                        component="a"
                                        aria-label="add"
                                        variant="extended"
                                        href="/templateWSDoc.csv" download
                                    >
                                        <GetAppIcon /> {"Descargar plantilla csv"}
                                    </Fab>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" color="primary" >
                                {"Aceptar"}
                            </Button>
                            <Button onClick={this.handleCloseDialog} color="secondary" autoFocus>
                                {"Cancelar"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}
            </Card>
        )
    }
}
// @ts-ignore
export default withStyles(useStyles)(WSDoc);