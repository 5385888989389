import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Send from '@material-ui/icons/Send';

import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "components/Snackbars/Snackbar";

import { Redirect } from 'react-router-dom';
// import UsuariosDataService from "../../services/usuarios.service";
import AdminDataService from "../../services/admin.service";
import Captcha from "../utils/Captcha";
class AdminLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { "form": { "username": "", "password": "" ,"g-recaptcha-response": "" }, "loading": false, "show": false, "redirect": false, "next": "/dashboard", "status": "success" };
    this.recaptchaRef = React.createRef();
  }
  handleClearSnack = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.show = false;
    statusCopy.msg = "";
    statusCopy.status = "success";
    this.setState(statusCopy);
  }

  handleChange = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState(statusCopy);
  }
  onChangeRecaptcha = (value) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form["g-recaptcha-response"] = value
    this.setState(statusCopy);
  }

  handleSubmit = (event) => {
    this.handleClearSnack(event);
    var data = JSON.stringify(this.state.form);
    this.setState({ "loading": true })
    AdminDataService.login(data)
      .then(response => {
        if (response.data.code === 1) {
          localStorage.setItem('currentUserToken', response.data.token);
          localStorage.setItem('currentUserExpires', response.data.expires);
          this.props.login();
          let statusCopy = Object.assign({}, this.state);
          statusCopy.show = true;
          statusCopy.msg = response.data.message;
          statusCopy.status = "success";
          statusCopy.redirect = true;
          statusCopy.next = "/admin/dashboard";
          this.setState(statusCopy)
          AdminDataService.getAuthToken();
        } else {
          let statusCopy = Object.assign({}, this.state);
          statusCopy.show = true;
          statusCopy.msg = response.data.message;
          statusCopy.status = "error";
          this.setState(statusCopy)
          if (this.recaptchaRef !== null){this.recaptchaRef.current.resetCaptcha()}
        }
        this.setState({ "loading": false })
        
      })
      .catch(e => {
        this.setState({ "loading": false })
        if (this.recaptchaRef !== null){this.recaptchaRef.current.resetCaptcha()}
        console.log(e);
        if (e.response.status === 403) {
          let statusCopy = Object.assign({}, this.state);
          statusCopy.show = true;
          statusCopy.msg = e.response.data.message;
          statusCopy.status = "error";
          this.setState(statusCopy)
        }
        if (e.response.status === 401) {
          let statusCopy = Object.assign({}, this.state);
          statusCopy.show = true;
          statusCopy.msg = e.response.data.message;
          statusCopy.status = "error";
          this.setState(statusCopy)
        }
      })
    // fetch('/login', {
    //     method: 'POST',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(this.state.form)
    //   }).then(handleResponse).then(data => {
    //     if (data.code === 1){
    //       localStorage.setItem('currentUserToken', data.token);
    //       localStorage.setItem('currentUserExpires', data.expires);
    //       let statusCopy = Object.assign({}, this.state);
    //       statusCopy.status.show= true;
    //       statusCopy.status.msg = data.message;
    //       statusCopy.status.status = "success";
    //       statusCopy.status.redirect = true;
    //       statusCopy.status.next = data.next;
    //       this.setState(statusCopy)

    //     }else{
    //       let statusCopy = Object.assign({}, this.state);
    //       statusCopy.status.show= true;
    //       statusCopy.status.msg = data.message;
    //       statusCopy.status.status = "error";
    //       this.setState(statusCopy)
    //     }
    //   });
    event.preventDefault();
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.handleSubmit}>
        <Card elevation={3}>
          <CardContent>
            <TextField onChange={this.handleChange} name="username" fullWidth id="username" label="Usuario" variant="outlined" required

            />
            <br></br>
            <br></br>
            <TextField onChange={this.handleChange} name="password" fullWidth id="password" label="Contraseña" variant="outlined" type="password" required
            />
            <br></br>
            <br></br>
            <Captcha onChangeRecaptcha={this.onChangeRecaptcha} ref={this.recaptchaRef} darkState={this.props.darkState} ></Captcha>
          </CardContent>
          <CardActions>
            <Button fullWidth
              disabled={this.state.loading}
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<Send />}
            >
              Ingresar
                </Button>
            {this.state.loading && <CircularProgress size={24} />}
          </CardActions>
          {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}
          {this.state.redirect ? <Redirect push to={this.state.next} /> : null}
        </Card>
      </form>
    );
  }
}

export default AdminLoginForm;