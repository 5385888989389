import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Send from '@material-ui/icons/Send';

import Snackbar from "components/Snackbars/Snackbar";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';

import InputAdornment from '@material-ui/core/InputAdornment';
import Home from '@material-ui/icons/Home';
import Phone from '@material-ui/icons/Phone';
import PhoneAndroid from '@material-ui/icons/PhoneAndroid';
import Email from '@material-ui/icons/Email';
import PhoneMask from "components/Masks/PhoneMask"
import CellphoneMask from "components/Masks/CellPhoneMask"
import EmailMask from "components/Masks/EmailMask"
import TwoFactorMask from "components/Masks/TwoFactorMask"

import analyze from "components/Password/analyze";
import UsuariosDataService from "services/usuarios.service";


import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Avatar, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import QRCode from "qrcode.react";
import logoAuthGoogle from 'assets/img/authenticator-google.svg';
import logoAuthMicrosoft from 'assets/img/authenticator-microsoft.svg';
class EditAccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "givenName": "", "lastName": "", "username": "", "form": { "changePassword": false, "password": "", "verificationPassword": "", "phone": "", "cellphone": "", "direction": "", "email": "" }, "redirect": false, "next": "/", "showToast": false, "msg": "", "status": "success", "loading": false,
      "otp": "", "enableOTP": false, "existOTP": false, "loadingOTP": false, "openVerifyOTP": false, "code2fa": "", "otpmessage": ""
    };
    this.getUser();
    this.getNewOTP(true);
  }

  getUser = () => {
    UsuariosDataService.getUser()
      .then(response => {
        try {
          if (response.data.code === 1) {
            var user = response.data.data;
            let statusCopy = Object.assign({}, this.state);
            statusCopy.form.direction = user.direction;
            statusCopy.form.phone = user.phone;
            statusCopy.form.cellphone = user.cellphone;
            statusCopy.form.email = user.email;
            this.setState({ "form": statusCopy.form });
            this.setState({ "givenName": user.givenName });
            this.setState({ "lastName": user.lastName });
            this.setState({ "username": user.username});
            this.setState({ "email": user.username+"@umsa.bo"});
          }
          this.setState(
            { "show": response.data.show, "showButton": response.data.showButton, "text": response.data.message, "code": response.data.code }
          );
        } catch (error) {
          console.log(error)
        }
      })
      .catch(e => {
        if (e.response.status === 401) {
          let statusCopy = Object.assign({}, this.state);
          statusCopy.redirect = true;
          statusCopy.next = "/";
          this.setState(statusCopy);
        }
      });
  }
  getNewOTP = (first = false) => {
    UsuariosDataService.getNewOTP().then(response => {
      if (response.data.code === 1) {
        // new OTP
        if (first) {
          this.setState({ "otp": response.data.otp })
          this.setState({ "enableOTP": false })
          this.setState({ "existOTP": false })
        } else {
          this.setState({ "otp": response.data.otp })
          this.setState({ "enableOTP": true })
          this.setState({ "existOTP": false })
        }
      } else {
        // Already Exist
        this.setState({ "enableOTP": true })
        this.setState({ "existOTP": true })
        this.setState({ "otpmessage": response.data.message })
      }
      this.setState({ "loadingOTP": false })
    }).catch(e => {
      console.log(e)
      this.setState({ "loadingOTP": false })
    });
  }

  handleClearSnack = (event) => {
    this.setState({ "showToast": false });
    this.setState({ "msg": "" });
    this.setState({ "status": "success" });
  }

  handleChange = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
  }


  handleChangeOTP = (event) => {
    this.setState({ "code2fa": event.target.value });
  }
  handleChangePass = (event) => {
    if (this.state.form.changePassword === false) {
      return;
    }
    var status = analyze(event.target.value);
    if (status.result === false) {
      if (status.number === false) {
        this.setState({ "showToast": true });
        this.setState({ "msg": "Debe tener almenos un número" });
        this.setState({ "status": "error" });
      } else {
        if (status.lower === false) {
          this.setState({ "showToast": true });
          this.setState({ "msg": "Debe tener almenos una letra en minúscula" });
          this.setState({ "status": "error" });
        } else {
          if (status.upper === false) {
            this.setState({ "showToast": true });
            this.setState({ "msg": "Debe tener almenos una letra en mayúscula" });
            this.setState({ "status": "error" });
          } else {
            if (status.length === false) {
              this.setState({ "showToast": true });
              this.setState({ "msg": "Debe tener como mínimo 8 caracteres" });
              this.setState({ "status": "error" });
            }
          }
        }
      }
    }
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
    if (status.result) {
      if (this.state.form.password !== this.state.form.verificationPassword) {
        this.setState({ "showToast": true });
        this.setState({ "msg": "Las contraseñas no coinciden" });
        this.setState({ "status": "error" });
      } else {
        this.setState({ "showToast": true });
        this.setState({ "msg": "Las contraseñas coinciden" });
        this.setState({ "status": "success" });
      }
    }
  }
  handleSwitchOTP = (event) => {
    this.setState({ "loadingOTP": true })
    if (event.target.checked === true) {
      // this.setState({"enableOTP": false})
      this.getNewOTP();
    } else {
      if (this.state.existOTP === false) {
        this.setState({ "loadingOTP": false })
        this.setState({ "enableOTP": false })
      } else {
        this.handleClickOpenVerify();
      }
    }
  }
  handleChangeSwitch = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.checked
    if (event.target.checked === false) {
      statusCopy.form.password = ""
      statusCopy.form.verificationPassword = ""
    }
    this.setState({ "form": statusCopy.form });
  }

  handleSubmitOTP = (event) => {
    this.closeVerifyOTP();
    var data = JSON.stringify({ "code": this.state.code2fa });
    UsuariosDataService.setNewOTP(data).then(response => {
      this.setState({ "showToast": true });
      this.setState({ "msg": response.data.message });
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
      if (response.data.code === 1) {
        this.getNewOTP();
      }
    }).catch(e => {
      console.log(e);
      this.getNewOTP();
    })
    event.preventDefault();
  }
  handleSubmitDeleteOTP = (event) => {
    this.closeVerifyOTP();
    var data = JSON.stringify({ "code": this.state.code2fa });
    UsuariosDataService.deleteNewOTP(data).then(response => {
      this.setState({ "showToast": true });
      this.setState({ "msg": response.data.message });
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
      if (response.data.code === 1) {
        this.setState({ "enableOTP": false })
      }
      this.setState({ "loadingOTP": false })
    }).catch(e => {
      console.log(e);
      this.getNewOTP();
      this.setState({ "loadingOTP": false })
    })
    event.preventDefault();
  }
  handleSubmit = (event) => {
    this.handleClearSnack(event);
    var canSend = true;
    if (this.state.form.changePassword) {
      if (this.state.form.password !== this.state.form.verificationPassword) {
        this.setState({ "showToast": true });
        this.setState({ "msg": "Las contraseñas no coinciden" });
        this.setState({ "status": "error" });
        canSend = false;
      } else {
        canSend = true;
      }
    }
    if (canSend) {
      var data = JSON.stringify(this.state.form);
      this.setState({ "loading": true })
      UsuariosDataService.editAccount(data)
        .then(response => {
          if (response.data.code === 1) {
            this.setState({ "showToast": true });
            this.setState({ "msg": response.data.message });
            this.setState({ "status": "success" });
            this.setState({ "next": "/success" });
            this.setState({ "redirect": true });
          } else {
            this.setState({ "showToast": true });
            this.setState({ "msg": response.data.message });
            this.setState({ "status": "error" });
          }
          this.setState({ "loading": false })
        })
        .catch(e => {
          this.setState({ "loading": false })

          console.log(e);
          if (e.response.status === 401) {
            this.setState({ "showToast": true });
            this.setState({ "msg": e.response.data.message });
            this.setState({ "status": "success" });
            this.setState({ "redirect": true });
            this.setState({ "next": "/" });
          } else {
            try {
              this.setState({ "msg": JSON.stringify(e.response.data.message[Object.keys(e.response.data.message)[0]]) });
              this.setState({ "showToast": true });
              this.setState({ "status": "error" });
            } catch (error) {
              console.log(error)
            }
          }
        })
    }
    event.preventDefault();
  }
  handleClickOpenVerify = () => {
    this.openVerifyOTP()
  }
  openVerifyOTP = () => {
    this.setState({ "openVerifyOTP": true })
  }
  closeVerifyOTP = () => {
    this.setState({ "openVerifyOTP": false })
    this.setState({ "loadingOTP": false })
  }
  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.next} />
    }

    return (
      <div>
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography >Nombres</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6" align="left"  >
              Usuario: {this.state.username}
              <br />
              Nombres: {this.state.givenName}
              <br />
              Apellidos: {this.state.lastName}
              <br />
              Correo institucional : {this.state.email}
              
            </Typography>
          </AccordionDetails>
        </Accordion>








        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography >Información Personal</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField onChange={this.handleChange} name="direction" fullWidth id="standard-direction" label="Dirección" variant="outlined" required
                  value={this.state.form.direction}
                  helperText="Dirección donde se encuentra el domicilio"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Home />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 70 }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField onChange={this.handleChange} name="phone" fullWidth id="standard-phone" label="Telefono" variant="outlined"
                  value={this.state.form.phone}
                  helperText="Número Telefónico fijo"
                  InputProps={
                    {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                      inputComponent: PhoneMask
                    }
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField onChange={this.handleChange} name="cellphone" fullWidth id="standard-cellphone" label="Celular" variant="outlined" required
                  value={this.state.form.cellphone}
                  helperText="Número Telefónico de celular"
                  InputProps={
                    {
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneAndroid />
                        </InputAdornment>
                      ),
                      inputComponent: CellphoneMask
                    }
                  }
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField onChange={this.handleChange} name="email" fullWidth id="standard-email" label="Email" variant="outlined" required
                  value={this.state.form.email}
                  helperText="Dirección de correo electrónico"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                    inputComponent: EmailMask
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.form.changePassword} >
          <AccordionSummary

            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.form.changePassword}
                    onChange={this.handleChangeSwitch}
                    name="changePassword"
                  />
                }
                label="Cambiar Contraseña" />
            </FormGroup>
          </AccordionSummary>
          <AccordionDetails>

            <br></br>
            {this.state.form.changePassword &&
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField onChange={this.handleChangePass} name="password" fullWidth id="password" label="Nueva Contraseña" variant="outlined"
                    helperText="Contraseña de la cuenta Institucional" type="password" inputProps={{ autoComplete: "new-password" }} required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField onChange={this.handleChangePass} name="verificationPassword" fullWidth id="verificationPassword" label="Repetir Contraseña " variant="outlined"
                    helperText="Contraseña de la cuenta Institucional" type="password" inputProps={{ autoComplete: "new-password" }} required
                  />
                </Grid>
              </Grid>

            }
          </AccordionDetails>
        </Accordion>

        <br />

        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Card elevation={3}>

            <CardActions>
              <Button fullWidth
                disabled={this.state.loading}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<Send />}
              >
                GUARDAR
                </Button>
              {this.state.loading && <CircularProgress size={24} />}
            </CardActions>
            {this.state.showToast && <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} />}
          </Card>
        </form>
        <br></br>
        <Accordion expanded={this.state.enableOTP} >
          <AccordionSummary

            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.enableOTP}
                    onChange={this.handleSwitchOTP}
                    name="enableOTP"
                    disabled={this.state.loadingOTP}

                  />
                }
                label="Habilitar 2do. Factor de Autenticación (Opcional)" />
            </FormGroup>
          </AccordionSummary>
          <AccordionDetails>

            <br></br>
            {this.state.enableOTP && !this.state.existOTP &&
              <Grid container spacing={1}>
                <Grid item sm={12} >
                    <Typography>Aplicaciones recomendadas para habilitar 2FA en Android</Typography>
                    <Chip
                      style= {{margin: 0.5}}
                      avatar={<Avatar src={logoAuthGoogle}></Avatar>}
                      label="Google Authenticator"
                      component="a"
                      variant="outlined"
                      color="primary"
                      target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                    />
                    <Chip
                    style= {{margin: 0.5}}
                      avatar={<Avatar src={logoAuthMicrosoft}></Avatar>}
                      label="Microsoft Authenticator"
                      component="a"
                      variant="outlined"
                      color="primary"
                      target="_blank" href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                    />
                </Grid>
                <Grid item sm={12}>
                  <QRCode value={this.state.otp} size={256} />
                  <IconButton color="primary" aria-label="reload" onClick={e => this.getNewOTP(false)}>
                    <ReplayIcon />
                  </IconButton >


                  <Button fullWidth
                    onClick={this.handleClickOpenVerify}
                    variant="contained"
                    color="primary"
                    endIcon={<Send />}>
                    VERIFICAR
                </Button>
                  <Dialog
                    open={this.state.openVerifyOTP}
                    onClose={this.closeVerifyOTP}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <form onSubmit={this.handleSubmitOTP}>
                      <DialogTitle id="alert-dialog-title">{"Habilitar la Autenticación a 2 pasos?"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Introduzca el código generado para la Autenticación a 2 pasos para habilitar el servicio
                    </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="code"
                          label="Código 2FA"
                          type="text"
                          fullWidth
                          onChange={this.handleChangeOTP}
                          required
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.closeVerifyOTP} color="secondary">
                          Cancelar
                    </Button>
                        <Button type="submit" color="primary" autoFocus>
                          Aceptar
                    </Button>
                      </DialogActions>
                    </form>
                  </Dialog>
                </Grid>
              </Grid>
            }
            {this.state.enableOTP && this.state.existOTP &&
              <div>
                <p>{this.state.otpmessage}</p>
                <Dialog
                  open={this.state.openVerifyOTP}
                  onClose={this.closeVerifyOTP}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <form onSubmit={this.handleSubmitDeleteOTP}>
                    <DialogTitle id="alert-dialog-title">{"Deshabilitar la Autenticación a 2 pasos?"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Introduzca el código generado para la deshabilitar la autenticación  a 2 pasos.
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="code"
                        label="Código 2FA"
                        type="text"
                        fullWidth
                        onChange={this.handleChangeOTP}
                        required
                        InputProps={
                          {
                            inputComponent: TwoFactorMask
                          }
                        }
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeVerifyOTP} color="secondary">
                        Cancelar
                      </Button>
                      <Button type="submit" color="primary" autoFocus>
                        Aceptar
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default EditAccountForm;