import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import UsernamePage from "views/Accounts/UsernamePage";
import UsuariosDataService from "services/usuarios.service";
import successimgEdit from 'assets/img/success_edit.svg';
import successimgActivate from 'assets/img/success_activate.svg';
import successimgMigrate from 'assets/img/success_migrate.svg';
import {  makeStyles } from '@material-ui/core';
import Link  from '@material-ui/core/Link';
class SuccessPage extends React.Component{
  constructor(props) {
    super(props);
    this.state = { "show":false, "action": "" , "username": ""};
    UsuariosDataService.getSuccessAction()
    .then(response => {
      try {
        if (response.data.code === 1){
          this.setState({"action": response.data.action} );
        }
      } catch (error) {
        console.log(error)
      }
    })
    .catch(e => {
      try {
        if (e.response.status === 403 || e.response.status === 401) {
          UsuariosDataService.logout();
          this.props.logout();
        }
      } catch (error) {
        console.log(error)
      }
    });
  }

  setUsername = (username) => {
    this.setState({"username": username})
  }
  renderSwitch(param) {
    switch(param) {
      case 'activate_account':
        return <ActivateAccount username={this.state.username} email={`${this.state.username}@umsa.bo`} logout={this.props.logout}/>;
      case 'edit_account':
        return <EditAccount username={this.state.username} email={`${this.state.username}@umsa.bo`} logout={this.props.logout}/>;
      case 'migrate_gsuit_account':
        return <MigrateGsuit username={this.state.username} email={`${this.state.username}@umsa.bo`} logout={this.props.logout}/>;
      default:
        return '';
    }
  }

  render(){
  return (
    <div>
      <Typography variant="h2"   gutterBottom>
          <UsernamePage initTitle="Felicidades" endTitle="!" returnUsername={this.setUsername}/>
      </Typography>
      
      {this.renderSwitch(this.state.action)}
    </div>
  );}
}

const useStyles = makeStyles({
  success: {
    width: "50%"
  }
});

const ActivateAccount = (props) => {
  
  const [count, setCount] = useState(10); 
  const [redirect, setRedirect] = useState(true); 
  useEffect(() => {
    const timerID = setInterval(() => {
      if (count > 0){
        setCount(count - 1)    
      }else{
        if (redirect){
          setRedirect(false);
          UsuariosDataService.logout();
          props.logout();
        }
      }
    },1000);
    return () => clearInterval(timerID);
  });
  const classes = useStyles();
  return (
  <div>
    <Typography variant="h5" gutterBottom>Se activó su cuenta institucional</Typography>
    <Typography variant="h6" gutterBottom>Su correo institucional es: {props.email} </Typography>
    <img src={successimgActivate} alt="success" className={classes.success} /> 
    <Typography variant="subtitle1" gutterBottom>Se envió un correo de información a su email personal que registró en el portal</Typography>
    <Typography variant="subtitle1" gutterBottom>Cerrando Sesión en {count} segundos...</Typography>
  </div>
  )};
const EditAccount = (props) => {
  
  const [count, setCount] = useState(10); 
  const [redirect, setRedirect] = useState(true); 
  useEffect(() => {
    const timerID = setInterval(() => {
      if (count > 0){
        setCount(count - 1)    
      }else{
        if (redirect){
          setRedirect(false);
          UsuariosDataService.logout();
          props.logout();
        }
      }
    },1000);
    return () => clearInterval(timerID);
  });
  const classes = useStyles();
  return (
  <div>
    <Typography variant="h5" gutterBottom>Se editó su cuenta institucional</Typography>
    <Typography variant="h6" gutterBottom>Su correo institucional es: {props.email} </Typography>
    <img src={successimgEdit} alt="success" className={classes.success} /> 
    <Typography variant="subtitle1" gutterBottom>Se envió un correo de información a su email personal que registró en el portal</Typography>
    <Typography variant="subtitle1" gutterBottom>Cerrando Sesión en {count} segundos...</Typography>
  </div>
  )};

  const MigrateGsuit = (props) => {
    const [count, setCount] = useState(10); 
    const [redirect, setRedirect] = useState(true); 
    useEffect(() => {
      const timerID = setInterval(() => {
        if (count > 0){
          setCount(count - 1)    
        }else{
          if (redirect){
            setRedirect(false);
            window.open('https://accounts.google.com/AddSession', "_accountgoogle");
            UsuariosDataService.logout();
            props.logout();
          }
        }
      },1000);
      return () => clearInterval(timerID);
    });
    const classes = useStyles();
    return (
    <div>
      <Typography variant="h5" gutterBottom>Se migró su cuenta institucional</Typography>
      <Typography variant="h6" gutterBottom>Su correo institucional es: {props.email} </Typography>
      <img src={successimgMigrate} alt="success" className={classes.success} /> 
      <Typography variant="subtitle1" gutterBottom>La información del correo anterior se encontrará en <Link href="https://correo.umsa.bo" target="_blank">correo.umsa.bo</Link> y su usuario sera <strong>{props.username}.old@umsa.bo</strong></Typography>
      <Typography variant="subtitle1" gutterBottom>Será redirigido a accounts.google.com en {count} segundos... podrá acceder a los servicios ingresando con su correo institucional y su contraseña, si no es redirigido haga click <Link href="https://accounts.google.com/AddSession"
                                  target="_accountgoogle">AQUÍ</Link></Typography>
    </div>
  )};
export default SuccessPage;