import React from 'react';
import UsuariosDataService from "services/usuarios.service";
class UsernamePage extends React.Component {
    constructor(props) {
      super(props);
      this.state = { "show":false, "username": "" };
      UsuariosDataService.getUsername()
      .then(response => {
        try {
          if (response.data.code === 1){
            this.setState({"username": response.data.username} );
            this.setState({"show": true});
            this.props.returnUsername(response.data.username);
          }
        } catch (error) {
          console.log(error)
        }
      })
      .catch(e => {
          console.log(e);
      });
    }
  
    render() {
      
      
      return (
        <div>
            {`${this.props.initTitle}  ${this.state.show ? this.state.username: ""} ${this.props.endTitle}`}
        </div>
      );
    }
  }

export default UsernamePage;