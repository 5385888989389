import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Snackbar from "components/Snackbars/Snackbar";
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from 'material-table'

import CircularProgress from '@material-ui/core/CircularProgress';
import tableIcons from "../utils/TableIcons"
import AdminDataService from "../../services/admin.service";
import { IconButton, TextField } from '@material-ui/core';
const useStyles = theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
});
class AdminLogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {"form": { "search": "" }, "rows": [], "show": false, "msg": "", "status": "success", }
    // this.handleReloadDataGrid();
  }
  handleClearSnack = (event) => {
    this.setState({ "show": false })
  }

  handleChange = (event) => {
    let statusCopy = Object.assign({}, this.state);
    statusCopy.form[event.target.name] = event.target.value
    this.setState({ "form": statusCopy.form });
  }

  handleSubmit = (event) => {
    this.setState({ "loading": true });
    var data = JSON.stringify(this.state.form);
    AdminDataService.listLogs(data).then(response => {
      this.setState({ "loading": false });
      if (response.data.code === 1) {
        const rows = []
        for (const item of response.data.data){
          item["id"] = item._id.$oid;
          item["date"] = new Date(item.dateMillis);
          item["data"] = JSON.stringify(item.data);
          rows.push(item);
        }
        this.setState({"rows": rows})
        
      }
    }).catch(e => {
      console.log(e)
      this.setState({ "loading": false });
    });
    event.preventDefault();
  }

  render() {
    const { classes } = this.props;
    return (
      <Card elevation={3}>
        <CardContent>
          <form onSubmit={this.handleSubmit}>
            <TextField onChange={this.handleChange} name="search" fullWidth id="search" label="Usuario o CI" variant="outlined" required
              helperText="Ingrese Carnet de Identidad o Usuario"
              InputProps={{
                endAdornment: (
                  <IconButton type="submit" className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                )
              }}
            />{this.state.loading && <CircularProgress size={24} />}
          </form>
          <div style={{ width: '100%' }}>
            <MaterialTable
              icons={tableIcons}
              columns={[
                { title: 'ID', field: 'id', hidden: true },
                { title: 'CI', field: 'ci' },
                { title: 'Usuario', field: 'username' },
                { title: 'Operación', field: 'operation', lookup: AdminDataService.listLogsLookup()}, // type: 'numeric'},
                { title: 'Fecha', field: 'date', type: "datetime", filtering: false }, // type: 'numeric'},
                { title: 'Data', field: 'data', filtering: false }, // type: 'numeric'},
              ]}
              data={this.state.rows}
              title="Historial"
              options={{
                filtering: true
              }}
            />
          </div>
        </CardContent>
        {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}
      </Card>
    );
  }
}
// @ts-ignore
export default withStyles(useStyles)(AdminLogs);