import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types'

function UsernameMask(props) {
    const { inputRef, ...other } = props;
    const maskArray = Array(30).fill(/[a-z-_0-9.]/)
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={maskArray}
        placeholderChar={'\u2000'}
        showMask
        guide={false}
      />
    );
  }

  UsernameMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

export default UsernameMask;