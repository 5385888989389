const lengthExp = "(?=.{8,})"
const upperExp = "(?=.*[A-Z])"
const lowerExp = "(?=.*[a-z])"
const numberExp = "(?=.*[0-9])"
const lengthRegex = new RegExp(`^${lengthExp}`)
const upperRegex = new RegExp(`^${upperExp}`)
const lowerRegex = new RegExp(`^${lowerExp}`)
const numberRegex = new RegExp(`^${numberExp}`)
const strongRegex = new RegExp(`^${lowerExp}${upperExp}${numberExp}${lengthExp}`);
export default function analyze(val){
  var result = strongRegex.test(val)
  var numberResult = numberRegex.test(val)
  var lowerResult = lowerRegex.test(val)
  var upperResult = upperRegex.test(val)
  var lenghtResult = lengthRegex.test(val)
  return {result: result, number: numberResult, lower: lowerResult, upper: upperResult, length:  lenghtResult};
}