import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table'

import  tableIcons from "../utils/TableIcons"
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AdminDataService from "../../services/admin.service";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import Snackbar from "components/Snackbars/Snackbar";
import UsernameMask from 'components/Masks/UsernameMask';
const useStyles  = theme => ({
  fab: {
    position:  "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
});
class AdminPermissions extends React.Component{
  defaultPermission = () =>  {return {"id": null,"username": "","permission": null,"init": null,"end": null}}
  constructor(props){
    super(props);
    
    this.state = {"rows": [], "openDelete": false, "idDelete": null, "show": false, "msg":"", "status": "success", "permission": this.defaultPermission(), "openRegisterEdit": false}
    this.handleReloadDataGrid();
  }
  handleReloadDataGrid = () =>{
    AdminDataService.listUserPermissions().then(response => {
      if (response.data.code === 1) {
        const rows = []
        for (const item of response.data.data){
          item["id"] = item._id.$oid;
          item["init"] = new Date(item.init);
          item["end"] = new Date(item.end);
          rows.push(item);
        }
        this.setState({"rows": rows})
      }
    });
  }
  handleClearSnack = (event) => {
    this.setState({ "show": false })
  }
  
  handleDeletePermission = (id)=> {
    this.setState({"idDelete": id})
    this.setState({"openDelete": true})
  }
  handleEditPermission = (permission)=> {
    this.setState({"permission": permission})
    this.setState({"openRegisterEdit": true})
  }
  handleCloseDialogDelete = () => {
    this.setState({"openDelete": false})
  }
  handleCloseDialogRegisterEdit = () => {
    this.setState({"openRegisterEdit": false})
  }
  handleSubmitDelete = (id) => {
    this.handleCloseDialogDelete();
    AdminDataService.deleteUserPermissions(id).then(response => {
      if (response.data.code === 1) {
        this.setState({"idDelete": null})
        this.handleReloadDataGrid();

      }
      this.setState({ "show": true })
      this.setState({ "msg": response.data.message })
      this.setState({ "status": response.data.code === 1 ? "success" : "error" })
    }).catch(e => {
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
            this.props.logout();
          }
        } catch (error) {
          console.log(error)
        }
      });
  }
  handleChangePermission = (event) => {
    let statusCopy = Object.assign({}, this.state.permission);
    statusCopy[event.target.name] = event.target.value
    this.setState({ "permission": statusCopy });
  }
  handleSetValuePermission = (id) => {
    let statusCopy = Object.assign({}, this.state.permission);
    statusCopy["permission"] = id;
    this.setState({ "permission": statusCopy });
  }
  handleDateChangeInit = (date) => {
    try {
      let statusCopy = Object.assign({}, this.state.permission);
      statusCopy.init = date;
      this.setState({ "permission": statusCopy });
    } catch (error) {
      console.log(error)
    }
  };
  handleDateChangeEnd = (date) => {
    try {
      let statusCopy = Object.assign({}, this.state.permission);
      statusCopy.end = date;
      this.setState({ "permission": statusCopy });
    } catch (error) {
      console.log(error)
    }
  };
  handleRegisterPermission = () => {
    if (this.state.permission.id !== null){
      this.setState({ "permission": this.defaultPermission() });
    }
    this.setState({"openRegisterEdit": true})
  };
  handleSubmit = (event) => {
    this.handleCloseDialogRegisterEdit();
    var permission = this.state.permission;
    permission.init = new Date(permission.init).getTime()
    permission.end = new Date(permission.end).getTime()
    var data = JSON.stringify(permission);
    if (this.state.permission.id === null){
      AdminDataService.addUserPermissions(data).then(response => {
        if (response.data.code === 1){
          this.setState({ "permission": this.defaultPermission() });
          this.handleReloadDataGrid();
        }else{
          this.setState({ "show": true })
          this.setState({ "msg": response.data.message })
          this.setState({ "status": "error" })
        }
      }).catch(e => {
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
            this.props.logout();
          }
        } catch (error) {
          console.log(error)
        }
      });
    }else{
      AdminDataService.editUserPermissions(data,permission.id).then(response => {
        if (response.data.code === 1){
          this.setState({ "permission": this.defaultPermission() });
          this.handleReloadDataGrid();
        }else{
          this.setState({ "show": true })
          this.setState({ "msg": response.data.message })
          this.setState({ "status": "error" })
        }
      }).catch(e => {
        try {
          if (e.response.status === 403 || e.response.status === 401) {
            AdminDataService.logout();
            this.props.logout();
          }
        } catch (error) {
          console.log(error)
        }
      });
    }
    event.preventDefault(); 
  };
  render(){
    const { classes } = this.props;
    return (
          <Card elevation={3}>
              <CardContent>
              <div style={{ width: '100%' }}>
              <MaterialTable
                icons={tableIcons}
                columns={[
                  { title: 'ID', field: 'id', hidden: true },
                  { title: 'Usuario', field: 'username' },
                  { title: 'Permiso', field: 'permission', lookup: AdminDataService.listPermissionsLookup()}, // type: 'numeric'},
                  { title: 'Inicio', field: 'init', type: "datetime", filtering: false}, // type: 'numeric'},
                  { title: 'Fin', field: 'end', type: "datetime", filtering: false}, // type: 'numeric'},
                ]}
                data={this.state.rows}
                title="Permisos"
                options={{
                  filtering: true
                }}
                actions={[
                  {
                    icon: () => <Edit />,
                    tooltip: 'Editar',
                    onClick: (event, rowData) => this.handleEditPermission(rowData)
                  },
                  {
                    icon:() => <Delete />,
                    tooltip: 'Borrar',
                    onClick: (event, rowData) => this.handleDeletePermission(rowData.id)
                  }
                ]}
              />
              </div>
              </CardContent>
              <Fab aria-label="Add" className={classes.fab} color='primary' onClick={this.handleRegisterPermission}>
                <AddIcon />
              </Fab>
            <Dialog
              open={this.state.openDelete && this.state.idDelete !== null}
              onClose={this.handleCloseDialogDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"Eliminar el Permiso?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Aceptar para confirmar la eliminación del permiso"}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e => this.handleSubmitDelete(this.state.idDelete)} color="primary" >
                  Aceptar
                </Button>
                <Button onClick={this.handleCloseDialogDelete} color="secondary" autoFocus>
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog 
              open={this.state.openRegisterEdit}
              onClose={this.handleCloseDialogRegisterEdit}
              aria-labelledby="alert-dialog-title"
              aria-describedby="reg-dialog-description">
              <form  onSubmit={this.handleSubmit}>
              <DialogTitle id="alert-dialog-title">{"Datos"}</DialogTitle>
              <DialogContent>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField onChange={this.handleChangePermission} name="username" fullWidth id="username" label="Usuario" variant="outlined" required
                      value={"username" in this.state.permission ? this.state.permission["username"]: ""} 
                      InputProps={{ inputComponent: UsernameMask }}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        value={this.state.permission["permission"]}
                        onChange={(event, newValue) => {
                          if (newValue !== null){
                            this.handleSetValuePermission(newValue.id);
                          }
                        }}
                        id="permissions"
                        options={AdminDataService.listPermissionsArray()}
                        getOptionSelected={(option, value) => {
                            return (option.id === value);
                          }
                        }
                        getOptionLabel={(option) => {
                            if (typeof(option)=== "string"){
                              option = AdminDataService.listPermissionsArray(option)[0];
                            }
                            return option.name
                          }
                        }
                        renderInput={(params) => <TextField {...params} label="Permiso" variant="outlined"  required/>}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
                        <KeyboardDateTimePicker
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        id="date-picker-init"
                        label="Inicio"
                        minDateMessage="Fecha de inicio no válida"
                        maxDateMessage="Fecha de inicio no válida"
                        maxDate={this.state.permission.end}
                        value={this.state.permission.init}
                        onChange={this.handleDateChangeInit}
                        invalidDateMessage="Ingrese una fecha válida"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }} required
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
                        <KeyboardDateTimePicker
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        id="date-picker-end"
                        label="Fin"
                        minDateMessage="Fecha fin no válida"
                        maxDateMessage="Fecha fin no válida"
                        minDate={this.state.permission.init}
                        value={this.state.permission.end}
                        onChange={this.handleDateChangeEnd}
                        invalidDateMessage="Ingrese una fecha válida"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }} required
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary" autoFocus>
                  Aceptar
                </Button>
                <Button onClick={this.handleCloseDialogRegisterEdit}  color="secondary">
                  Cancelar
                </Button>
              </DialogActions>
              </form>
            </Dialog>
            {this.state.show ? <Snackbar msg={this.state.msg} status={this.state.status} clear={this.handleClearSnack} /> : null}
          </Card>
    );}
}
// @ts-ignore
export default   withStyles(useStyles)(AdminPermissions) ;