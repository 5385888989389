import React from 'react';
import Typography from '@material-ui/core/Typography';
import VerifyPasswordForm from "views/Accounts/VerifyPasswordForm";
import ForgotPasswordForm from "views/Accounts/ForgotPasswordForm";
import UsernamePage from "views/Accounts/UsernamePage";
import MigrationStatePage from "views/Google/MigrationStatePage";
const handleUsername =  (username) =>{}
function VerifyPasswordPage(){
  
  return (
    <div>
      <Typography variant="h2"   gutterBottom>
        <UsernamePage initTitle="Hola," endTitle="" returnUsername={handleUsername}/>
      </Typography>
      <MigrationStatePage/>
      <br/>
      <br/>
      <Typography variant="h6" gutterBottom>
          Ingrese su contraseña para acceder a su información
      </Typography>
      <VerifyPasswordForm/>
      <br/><br/>
      <ForgotPasswordForm/>
    </div>
  );
}
export default VerifyPasswordPage;