import React from 'react';
import Typography from '@material-ui/core/Typography';
import LoginForm from "views/Login/LoginForm";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';

import MuiAlert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';
import YouTubeIcon from '@material-ui/icons/YouTube';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

class LoginPage extends React.Component{

  render(){
   return (
    <div>
      <Typography variant="h2"   gutterBottom>
          Bienvenido
      </Typography>
      <Typography variant="h6" gutterBottom>
          Para activar su cuenta debe seguir los siguientes pasos
      </Typography>
      <LoginForm login={this.props.login} darkState={this.props.darkState} />

      <br></br>
      <div>      
          <Card elevation={3}>
              <Alert severity="info"><Typography variant="h6">Mensaje !</Typography></Alert>
              <CardContent>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <YouTubeIcon/>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemLink href="https://bit.ly/umsausuarios"  target="_blank">
                    <ListItemText primary="Más información acerca de las operaciones sobre la cuenta institucional aquí" />
                  </ListItemLink>
                </ListItem>
                <Divider />
              </CardContent>

              <Divider/>
          </Card>
          
      </div>     
      
    </div>
  );}
}
export default LoginPage;