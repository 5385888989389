import React from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import AdminDataService from "../../services/admin.service";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper>
          {children}
        </Paper>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class AdminDashboardPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {"permissions": []}
    AdminDataService.dashboard().then(response => {
      if (response.data.code === 1){
        let permissions = []
        for (const iterator of response.data.routes) {
          permissions.push(iterator.permission);
        }
        this.setState({"permissions": permissions, "value": 0});
      }
    }).catch(e => {
      console.log(e);
      if( e.response.status === 403 || e.response.status === 401){
        AdminDataService.logout();
        this.props.logout();
      }
    })
  }
  handleChange = (event, newValue) => {
    this.setState({"value":newValue});
  }

  render(){
    
    var tabs = [];
    var content = [];
    var permissions = AdminDataService.listPermissions();
    var index = 0;
    for (const iterator of this.state.permissions) {
      if (iterator in permissions){
        var perm = permissions[iterator];
        if (perm.tab){
          tabs.push(<Tab icon={perm.icon} label={perm.text} {...a11yProps(index)}  key={index}/>)
          content.push(
            <TabPanel value={this.state.value} index={index} key={index}>
              <perm.view permissions={this.state.permissions} logout={this.props.logout}/>
              {/* <perm.view text={perm.text}  key={index}/> */}
            </TabPanel>
          )
          index++;
        }
      }
    }
  return (
    <div>
      
      <AppBar position="static">
        <Tabs value={this.state.value} onChange={this.handleChange} aria-label="Tabs Admin"
            variant="scrollable"
            scrollButtons="on">
          {tabs}
        </Tabs>
      </AppBar>

      <Container maxWidth="lg">
        <Box my={3}>
          {content}
        </Box>
      </Container> 

    </div>
  );}
}
export default  AdminDashboardPage;